.contact-page {
  color: var(--background);
  background-color: var(--opposite);

  .contact-page-container {
    display: flex;
    justify-content: space-around;
    max-width: 1311px;
    margin: auto;

    @media screen and (max-width: 768px) {
      flex-direction: column;

      .left {
        padding-top: 40px;
        text-align: center;
      }

      .right {
        padding-top: 0px;
        padding-bottom: 60px;
      }
    }
  }

  h2 {
    font-size: 36px;
    font-weight: 700;
  }

  .left,
  .right {
    width: 50%;
    padding: 80px 5vw;
  }

  h3 {
    font-size: 16px;
    font-weight: 700;
  }
  .meta {
    margin: 30px 0;

    h3 {
      font-size: 20px;
      margin-bottom: 20px;
    }

    .meta-content {
      border-top: 1px solid var(--secondary-faded);
      padding-top: 20px;
      display: flex;

      a {
        display: flex;
        margin-right: 20px;
        img {
          height: 26px;
          width: 26px;
          margin-right: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    p {
      font-size: 14px;
    }

    .left,
    .right {
      width: 100%;
      padding: 10px 15px;

      .meta-content {
        flex-direction: column;
        align-items: center;
        a {
          margin: 7.5px 0;
        }
      }
    }

    .left {
      padding-top: 80px;
    }
    .right {
      padding-bottom: 40px;
    }
  }
}

.contact-form {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 15px;

  .button {
    margin-top: 10px;
    height: 52px;

    &.disabled {
      cursor: not-allowed;
    }
  }

  .single {
    padding: 5px;
    position: relative;
  }
  .dropdown {
    .dropdown-selected {
      margin-bottom: 0;
    }
  }

  .single,
  .double {
    label {
      position: absolute;
      top: 25px;
      left: 15px;
      font-size: 12px;
      color: var(--background);
      opacity: 0;
      transition: 0.25s ease-in-out;

      &.filled {
        top: 7.5px;
        opacity: 0.25;
      }
    }
  }

  .double {
    display: flex;
    justify-content: space-between;
    padding: 5px;

    div {
      position: relative;
      width: 49%;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin-top: -10px;

      div {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}
