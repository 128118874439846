.login-modal {
  height: 100%;

  .login-page {
    height: 100%;
    .loginInfo {
      background: transparent;
    }
  }

  .content {
    height: 817px;
    width: 499px;

    .close-icon {
      position: fixed !important;
      top: 15px;
      right: 15px;
      cursor: pointer;
      opacity: 0.75;
      height: 30px !important;
      width: 30px !important;
    }
  }
}
