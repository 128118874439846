.teamsnap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .container {
    max-width: 1200px;
    width: 50%;
    max-width: 800px;
    padding: 0 5%;

    .dropdown {
      margin: 20px 0;
    }

    input {
      margin-bottom: 20px;
    }
  }
  h1 {
    font-size: 56px;
    font-weight: 800;
    line-height: 1.1;
    margin-bottom: 20px;
  }

  p {
    opacity: 0.85;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    .container {
      width: 100%;
      padding: 100px 15px 20px;

      h1 {
        font-size: 31px;
      }
    }
  }
}
