.survey {
  background: linear-gradient(
    235.16deg,
    rgba(60, 60, 60, 0.9) 1.7%,
    rgba(0, 0, 0, 0.9) 94.95%
  );
  height: 100vh;
  color: white;
  font-size: 20px;
  height: 100vh;
  overflow: auto;
  min-height: 600px;

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: 540px;
    padding: 10px;
    margin: auto;

    &.hidden {
      display: none;
    }

    .progress {
      opacity: 0.5;
      margin: 30px auto;
    }

    .question {
      text-align: center;
      h3 {
        margin-bottom: 0;
        font-size: 28px;
        font-weight: 800;
        //line-height: 34px;
        letter-spacing: -1px;
      }
      .hint {
        opacity: 0.5;
      }
    }

    .selections {
      width: 100%;
      padding: 10px 0;
      margin: 10px auto 20px;
      max-height: 75vh;
      overflow: scroll;
      text-transform: capitalize;

      &.other-selections {
        margin: 20px auto 30px;
      }

      .add-container {
        display: flex;
        font-size: 16px;
        cursor: pointer;
        width: fit-content;
        margin-left: 15px;
      }

      .add {
        filter: invert(1);
        margin-right: 5px;
        margin-top: 2px;
        height: 18px;
        width: 18px;
        object-fit: contain;
      }

      .selection,
      input {
        height: 60px;
        border-radius: 8px;
        background-color: black;
        margin: 10px auto;
        display: flex;
        align-items: center;
        padding: 0 20px;
        font-size: 20px;
        cursor: pointer;
        position: relative;

        .close {
          position: absolute;
          right: 7.5px;
          top: 22px;
          height: 14px;
          width: 14px;
          opacity: 0.5;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }

        img {
          margin-right: 10px;
          object-fit: cover;
          height: 21px;
          width: 21px;

          &.checked {
            padding: 2.5px;
          }
        }
      }
      input {
        color: white;
        padding: 0;
        cursor: initial;
      }
    }

    .button-container {
      display: flex;
      width: 90%;

      .survey-button {
        background: rgba(255, 255, 255, 0.2);
        margin: 10px auto;
        color: white;
        cursor: not-allowed;
        height: 52px;
        width: 100%;
        font-size: 16px;
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 5px;
        padding-right: 5px;

        &.back {
          cursor: pointer;
        }

        img {
          margin-right: 5px;
        }

        &.active {
          background: #fff200;
          color: black;
          cursor: pointer;

          img {
            filter: invert(1);
          }
        }
      }
    }
  }

  .options {
    margin-top: 15px;
    font-size: 16px;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    margin-top: 64px;

    .section {
      padding: 10px 15px;
      justify-content: flex-start;

      .button-container {
        width: 100%;
      }

      .progress {
        text-align: center;
        font-size: 14px;
        margin: 15px auto;
      }

      .question {
        h3 {
          font-size: 22px;
        }
        .hint {
          font-size: 16px;
        }
      }
    }
  }
}
