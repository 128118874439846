.settings {
  padding: 80px 0;
  width: 100%;
  min-height: calc(100vh - 64px);
  background: #000;

  .modals {
    .changeNameModal,
    .changePasswordModal {
      background-color: var(--backgroundSecondary);
      h3 {
        color: var(--default);
      }
      .nameBtnWrapper,
      .btnContainer {
        background-color: var(--modal-background);
      }
    }
  }
  .settingsContainer {
    .settingsTitle {
      font-size: 56px;
      font-weight: 800;
      line-height: 1;
      color: #fff;
    }
  }
  @media (max-width: 600px) {
    padding: 60px 0;
    width: 100%;
    .settingsContainer {
      .settingsTitle {
        font-size: 36px;
      }
    }
  }
}
