.hero-simple {
  overflow: hidden;
  height: 45vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  text-align: center;

  .landingBgInfo {
    position: relative;
  }

  b {
    color: var(--primary);
  }

  hr {
    width: 59px;
    border-width: 3px;
    border-color: var(--primary);
    margin: 20px auto;
  }

  span {
    color: var(--primary);
    font-weight: 800;
  }

  h1 {
    font-size: 72px;
    font-weight: 800;
    line-height: 1;
    color: var(--opposite);
  }

  p {
    font-weight: 500;
    font-size: 20px;
    color: var(--opposite);
  }

  video {
    object-fit: cover;
    object-position: center -5vh;
    width: 100vw;
    height: calc(45vh);
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 0;
  }

  .meta {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    position: relative;
    max-width: 600px;

    .item {
      padding: 0 5px;
      margin: 5px auto;
      font-size: 14px;
      text-align: center;
      border-right: 1px solid var(--secondary-hover);
      width: 200px;

      &:last-child {
        border: none;
      }

      h3 {
        font-size: 26px;
        font-weight: 800;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 20px;
    h1 {
      font-size: 31px !important;
    }
    p {
      font-size: 14px;
    }

    hr {
      margin: 15px auto;
    }

    .meta {
      margin: 5px 0 5px;

      .item {
        font-size: 16px;
        width: 100px;
        line-height: 1.25;

        h3 {
          font-size: 22px;
          font-weight: 700;
        }
      }
    }
  }
}
