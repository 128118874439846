nav {
  .navbarRightMenu {
    color: white;
    .link {
      opacity: 1;
      font-weight: 600;
      font-size: unset;
    }
  }

  .sign-in {
    opacity: 1 !important;
  }

  .navMenuWrapper .navbarLeftMenu .navLink {
    img.icon {
      height: 20px;
      width: auto;
      margin-left: 5px;
    }
  }

  .navMenuWrapper.mobileWrapper .navbarLeftMenu .navLink {
    display: flex !important;

    img {
      height: 25px;
      width: auto;
    }
  }
  .navMenuWrapper.mobileWrapper {
    padding: 40px 20px;
  }
}

.sticky-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  background-color: var(--backgroundSecondary);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 99991;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
  border-radius: 0;
  opacity: 1;
  transition: 0.25s ease-in-out;

  &.hide-nav {
    opacity: 0;
    height: 0;
    visibility: hidden;
  }

  .button {
    width: 165px !important;
    margin: 0 0 0 30px !important;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;

    .button {
      padding: inherit 0;
      white-space: nowrap;
    }
  }
}
