.article-page {
  min-height: 100vh;
  background-color: var(--opposite);
  padding: 84px 20px;
  color: var(--background);

  .membership-banner {
    height: 768px;
    position: relative;
    background-color: var(--background);
    color: var(--default);
    padding: 5%;
    height: 560px;
    border-radius: 12px;
    overflow: hidden;

    .overlay {
      border-radius: 12px;
    }

    .card-body {
      width: 40%;
      min-width: 320px;
    }

    h3 {
      font-size: 42px;
      font-weight: 700;
      line-height: 1.25;
      margin-bottom: 10px;
      position: relative;
    }

    .button {
      margin-top: 20px;
      width: 200px;
      position: relative;
    }

    img {
      position: absolute;
      right: -15%;
      bottom: 0;
      z-index: 0;
    }
  }

  .content-container {
    display: flex;
    justify-content: space-between;
  }

  .right {
    .card-collection {
      margin: 40px auto;
      justify-content: center;

      .card,
      video {
        margin: auto;
        height: 625px !important;
        width: 353px !important;
        max-width: 353px !important;
      }
    }
  }

  .signup {
    height: fit-content;
    width: 353px;
    background-color: var(--background);
    border-radius: 8px;
    color: var(--default);
    padding: 20px 20px 10px;

    h3 {
      font-size: 24px;
      font-weight: 800;
      line-height: 1.25;
      margin-bottom: 10px;
    }
  }

  .email-hero {
    margin: 120px auto;
  }

  .email-hero-form {
    margin: 20px auto;
    input,
    .button {
      height: 50px;
      margin-bottom: 20px;
    }

    .additionalInfo {
      margin: 15px 0 0;
      color: #9e9e9e;
      font-size: 14px;
      font-weight: 400;
      display: flex;

      img {
        margin-right: 5px;
        cursor: pointer;
      }
      a {
        text-decoration: underline;
        &:hover {
          color: #fff;
        }
      }
    }
  }

  .htmlBody {
    width: 60%;

    blockquote {
      strong {
        font-size: 56px;
        font-weight: 700;
        line-height: 1;
        color: var(--background);
      }
    }

    ul {
      li {
        font-weight: 700;
        margin: 10px 0;
        color: #535353;
      }
    }
    h2 {
      font-size: 32px;
      font-weight: 700;
      margin: 20px auto 10px;
      line-height: 1.1;
    }

    h3 {
      font-size: 24px;
      font-weight: 700;
      margin: 20px auto 10px;
      color: #535353;
    }

    p {
      color: #535353;
    }

    img {
      margin: 20px auto;
      border-radius: 8px;
    }
  }

  .athlete-banner {
    display: flex;
    background-color: var(--background);
    height: 256px;
    border-radius: 8px;
    color: var(--default);
    align-items: center;
    overflow: hidden;
    margin-top: 20px;

    h3 {
      color: var(--default);
      font-size: 36px;
      margin: 0;
      line-height: 1.1;
      margin-bottom: 20px;
      text-transform: capitalize;
    }

    .left {
      display: flex;
      flex-direction: column;
      margin-left: 25px;
      width: 50%;
    }

    .right {
      position: relative;
      height: 256px;
      font-size: 24px;
      width: 50%;

      .overlay {
        z-index: 1;
      }

      img {
        position: absolute;
        top: -6.5%;
        bottom: 0;
        right: 0;
        left: 0;
        height: 95%;
        object-fit: cover;
        width: auto;
        object-position: 85% center;
      }

      h4 {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 2;
      }
    }

    .button {
      width: 200px;
    }
  }

  .article-body {
    max-width: 1129px;
    margin: auto;

    h1 {
      font-size: 56px;
      font-weight: 700;
      margin: 20px 0;
      line-height: 1.1;
    }

    .card {
      .meta {
        font-weight: 400;
        text-transform: capitalize;
        img {
          height: 20px;
          width: 20px;
        }
      }
    }

    .meta {
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: space-between;

      label {
        margin-right: 5px;
      }

      div {
        display: flex;
      }

      .social {
        img {
          cursor: pointer;
        }
      }

      span {
        margin-right: 20px;
        display: flex;
      }
    }

    .post-summary {
      margin: 40px auto;
      font-size: 56px;
      line-height: 1.1;
    }

    .post-image {
      border-radius: 8px;
      margin: 20px 0;
    }
  }

  .ad-banner {
    height: 202px;
    border-radius: 8px;
    margin: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px;
    position: relative;

    .overlay {
      border-radius: 8px;
    }

    h3 {
      color: var(--default);
      font-size: 32px;
      font-weight: 700;
      max-width: 400px;
      line-height: 1.25;
      position: relative;
    }

    .button {
      width: 200px;
      position: relative;
    }

    .post-body {
      img {
        margin: 20px auto;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 84px 20px 40px;

    .right {
      .card-collection {
        .collection-container {
          .card,
          video,
          .overlay {
            height: 540px !important;
            border-radius: 12px;
          }
        }
      }
    }

    .article-body {
      h1 {
        font-size: 26px;
      }
      .meta {
        flex-direction: column;

        .meta-details {
          display: flex;
          justify-content: space-around;
          width: 100%;
          margin: -5px 0 5px;

          span {
            margin: 0;
            width: unset;
          }
        }

        span {
          width: 100%;
          margin: 0;
        }

        .social {
          margin: 10px 0 0;
          justify-content: center;
          z-index: 1;
        }
      }

      .membership-banner {
        margin-top: 40px;
        margin-bottom: 0;
        h3 {
          font-size: 30px;
        }
      }

      .email-hero {
        margin: -20px auto 30px;
        display: none;
      }

      .ad-banner {
        padding: 40px 20px;
        flex-direction: column;
        text-align: center;
        h3 {
          font-size: 22px;
        }
      }

      .content-container {
        flex-direction: column;

        .left {
          z-index: 2;
        }

        .right {
          margin-top: 40px;

          h4 {
            display: none;
          }
        }

        .htmlBody {
          width: 100%;

          .post-summary {
            font-size: 26px;
            line-height: 1.25;
          }
        }
      }
    }
  }
}
