.course {
  padding: 64px 0 0;

  .prominent {
    position: relative;
  }

  .side-bar-header-tabs {
    position: relative;
  }

  .watched-indicator {
    height: 3px;
    background-color: var(--primary);
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .session-summary {
    display: flex;
    padding: 10vh 7.5vw 0;
    margin: auto;

    .button {
      width: fit-content !important;
    }

    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .button {
        width: 100px;
      }
    }

    .left {
      padding-right: 2.5vw;
    }
    h3 {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.25;
    }
    h5 {
      font-size: 12px;
      font-weight: 400;
      margin: 0 0 10px;
      opacity: 0.5;
    }
    h6 {
      font-size: 14px;
      font-weight: 400;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      color: var(--opposite-body);
    }
  }

  .controls .progress {
    cursor: pointer;
    width: 75.390625%;
  }

  .sidebar {
    width: 330px;
    height: 100%;
    overflow: auto;
    background-color: var(--background);
    color: var(--background);
    position: relative;
    max-width: 330px;

    .sidebar-header {
      font-size: 18px;
      height: 210px;
      //display: flex;
      //align-items: center;
      //padding: 10px;
      font-weight: 600;
      background-color: var(--background);
      color: var(--opposite);
      position: absolute;
      top: 0px;
      bottom: 0;
      right: 0;
      cursor: pointer;

      .tabs {
        justify-content: center;
        align-items: flex-end;
        margin-top: 14px;
        .tab {
          font-size: 14px;
          color: var(--opposite-body-hover);
          padding: 0 10px 5px;
          width: fit-content;
          white-space: nowrap;
          &.active {
            color: var(--default);
            border-color: var(--default);
          }
        }
      }

      .side-bar-header-card {
        height: 168px;
        border: 1px solid var(--background);
        width: 330px;
        background-size: cover;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3 {
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 2.5px;
        }

        h4 {
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 0;
        }

        p {
          font-size: 12px;
          font-weight: 400;
        }

        .button {
          max-width: 150px;
          font-size: 12px;
          height: 31px;
        }
      }

      &.active {
        .icon {
          transform: rotate(180deg);
        }
      }

      &:hover {
        .icon {
          opacity: 1;
        }
      }

      small {
        margin-left: 5px;
        opacity: 0.5;
        font-weight: 400;
        font-size: 14px;
      }

      .icon {
        position: absolute;
        right: 10px;
        top: 13px;
        opacity: 0.5;
        transition: 0.25s ease-in-out;
      }
    }

    .header-dropdown {
      background-color: var(--background);
      color: var(--opposite);

      .header-dropdown-item {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        font-weight: 500;

        h5 {
          margin-bottom: -5px;
        }

        small {
          margin-right: 5px;
          opacity: 0.5;
        }

        img {
          height: 60px;
          width: 60px;
          border-radius: 8px;
          margin-right: 10px;
          transition: 0.25s ease-in-out;
          background-color: var(--opposite-body-hover);
          object-fit: cover;
        }
      }
    }
    .sidebar-body {
      //height: calc(100% - 40px);
      overflow: auto;
      position: absolute;
      top: 210px;
      bottom: 0;

      &::-webkit-scrollbar {
        display: none;
      }
      .sidebar-item {
        height: 76px;
        font-size: 11px;
        justify-content: center;
        background-color: var(--opposite-body);
        cursor: pointer;
        border-bottom: 1px solid var(--opposite-body-hover);
        overflow: hidden;
        position: relative;
        color: var(--background);

        .badge {
          font-size: 10px;
          background-color: var(--primary);
          padding: 0px 5px;
          border-radius: 15px;
          color: var(--background);
          font-weight: 500;
          vertical-align: top;
          width: 56px;
          text-align: center;

          /* position: absolute;
          right: 5px;
          top: 5px; */
        }

        &:hover {
          background-color: var(--opposite-highlight);
          color: var(--background);
        }

        &.active {
          background-color: var(--backgroundSecondary);
          color: var(--opposite);
        }

        .sidebar-item-copy {
          margin-left: 115px;
          height: 100%;
          padding: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        h5 {
          font-size: 15px;
          font-weight: 500;
          line-height: 1.1;
          margin-bottom: 5px;
        }
        .card-image {
          background-color: var(--opposite-body-hover);
          height: 76px;
          width: 111px;
          background-size: cover;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          background-position: center;
        }
      }
    }
  }

  .video-player-container {
    position: relative;
    background-color: black;
    height: calc(80vh - 64px);
    display: flex;
    width: 100vw;

    &.show-inbetween {
      background-color: var(--opposite-body);
      border-right: 1px solid var(--opposite-body-hover);

      h3 {
        color: var(--background);
      }
    }

    /* .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.75);
    } */

    .watch-again,
    .next-up {
      .overlay {
        background-color: transparent;
        background: linear-gradient(
          120deg,
          rgba(0, 0, 0, 0.55) 25%,
          rgba(0, 0, 0, 0) 100%
        );
      }
      position: relative;
      .icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;
        opacity: 0.8;
        z-index: 2;

        &:hover {
          opacity: 1;
        }
      }
    }

    .main-video {
      max-height: calc(100vh - 64px);
      width: calc(100% - 330px);
      height: 100%;
    }
  }
}

.slide {
  display: flex;
  max-height: calc(100vh - 64px);
  width: 100%;
  min-width: calc(100vw - 300px);
  height: 100%;
  padding: 1vw;

  .left {
    width: 35%;
    padding: 20px;

    .watch-again {
      height: 26.5vh;
      width: 100%;
      border-radius: 8px;
      background-size: cover;
      background-position: center;
      margin-bottom: 3vh;

      &.explore-ai {
        background-position: 30vw center;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        h2,
        h4 {
          font-weight: 700;
          line-height: 1.25;
          font-size: 24px;
          position: relative;
        }

        a {
          position: relative;
          width: 185px;
          margin-bottom: 2.5px;
        }
      }
    }
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1vh;
    height: 3vh;
  }

  h4 {
    font-size: 16px;
    font-weight: 700;
    position: relative;
  }

  h5 {
    font-size: 12px;
    font-weight: 500;
    position: relative;
  }

  .watch-again,
  .next-up {
    padding: 20px;
    overflow: hidden;
    position: relative;
  }

  .right {
    width: 65%;
    padding: 20px;

    .next-up {
      width: 100%;
      height: 60vh;
      border-radius: 8px;
      background-size: cover;
      background-position: center;
      position: relative;

      .button {
        width: 180px;
        position: absolute;
        bottom: 20px;
        right: 20px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .course {
    .sidebar {
      width: 100%;
      min-width: 100vw;
      position: relative;

      .sidebar-header {
        position: relative;
        .tabs {
          margin: 0;
          padding-top: 7.5px;
        }
        height: fit-content;
        .side-bar-header-card {
          display: none;
        }
      }
      .sidebar-body {
        position: relative;
        top: 0;
        border-top: 1px solid var(--background);
        display: flex;
        height: 220px;
        //overflow-y: hidden;
        /* overflow-y: hidden;
        overflow-x: auto;
        .card-grid::-webkit-scrollbar {
          display: none;
        } */

        .sidebar-item {
          width: 200px;
          min-width: 200px;
          height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          &.active {
            background-color: var(--default);
            color: var(--background);
          }

          .card-image {
            position: relative;
            width: 200px;
            height: 120px;
            min-height: 120px;
            object-fit: cover;
          }
          .sidebar-item-copy {
            margin: 0;
            padding: 0 15px;
            height: 80px;
          }
        }
      }
    }
  }

  .slide {
    /* display: flex;
    max-height: calc(100vh - 64px);
    width: 100%;
    min-width: calc(100vw - 300px);
    height: 100%;
    padding: 1vw; */
    flex-direction: column;

    .right,
    .left {
      padding: 0 10px;
      width: 100%;

      .next-up {
        .button {
          width: 200px;
          position: relative;
          margin-top: 30px;
          top: unset;
          right: unset;
        }
      }

      .watch-again,
      .next-up {
        height: 130px;
        margin-bottom: 15px;

        img.icon {
          right: 15px;
          left: unset;
        }

        &.explore-ai {
          background-position: unset;
          h2,
          h4 {
            font-size: 16px;
            display: inline;
          }

          h4 {
            margin-left: 5px;
          }
          .button {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .course {
    .video-player-container {
      flex-direction: column;
      width: 100vw;
      height: unset;

      .main-video {
        width: 100%;
        /* width: 100%;
    position: fixed;
    left: 0;
    top: 64px;
    right: 0;
    height: fit-content;
    z-index: 3; */
      }
    }

    .session-summary {
      flex-direction: column;
      padding: 40px 20px 20px;

      .title {
        align-items: center;
        flex-direction: column;
      }
      h3 {
        font-size: 22px;
        margin-bottom: 10px;
      }

      .right {
        margin-top: 20px;
        width: 100%;

        .block.talk-block {
          width: 100%;
        }
      }
    }
  }
}
