.collection {
  position: relative;
  color: var(--default);

  .title-with-tabs {
    margin-left: 0;
    margin-top: 60px;
  }

  .bottom {
    .title-with-tabs {
      h4 {
        color: var(--opposite);
        margin-left: 7.5vw !important;
      }
    }
  }

  .card-collection {
    a {
      &:first-child {
        margin-left: 7.5vw;
      }
    }
  }

  .collections-section {
    padding: 10px 0 80px;
    .card-collection {
      display: flex;
      flex-wrap: nowrap;
      &::-webkit-scrollbar {
        display: none;
      }

      .card {
        min-width: 299px;
      }
    }
  }

  .breadcrumbs {
    margin-bottom: 10px;
  }

  h6 {
    text-transform: capitalize;
  }

  .browse-content {
    .collections-section {
      h4 {
        margin-left: 7.5vw !important;
        color: var(--background);
      }
    }

    .hArrows {
      img {
        filter: invert(1);
      }
    }
    .card-stack .card {
      border-color: var(--opposite-body);
    }
  }

  .bottom {
    padding: 40px 0;
  }

  .collections-section .card-collection .card {
    min-width: 380px;
  }

  .collapse-container {
    max-width: 1312px;
    margin: auto;
    padding: 20px;

    .collapse {
      background-color: var(--opposite-highlight);
      margin-bottom: 20px;
      border-radius: 16px;
      color: var(--background);
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.active {
        height: fit-content;

        .collapse-header {
          img {
            transform: rotate(-180deg);
          }
        }
      }

      .collapse-body {
        padding: 0 30px 40px;

        .body {
          .item {
            height: 256px;
            border-radius: 8px;
            margin: 20px auto;
            background-color: var(--opposite-body);
            display: flex;
            cursor: pointer;
            .left {
              height: 100%;
              width: 30%;
              min-width: 30%;
              background-size: cover;
              background-position: center;
              border-radius: 8px 0 0 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;

              .watched-indicator {
                height: 5px;
                background-color: var(--primary);
                position: absolute;
                bottom: 0;
                left: 0;
              }
            }
            .right {
              padding: 3vw;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;

              h5 {
                font-size: 24px;
                font-weight: 700;
                line-height: 1.25;
                margin-bottom: 10px;
                display: flex;
                align-items: center;

                .badge {
                  font-size: 12px;
                  background-color: var(--primary);
                  padding: 5px 10px;
                  border-radius: 15px;
                  color: var(--background);
                  font-weight: 400;
                  vertical-align: middle;
                  margin: 0 10px;
                }
              }
              p {
                font-size: 16px;
                font-weight: 400;
              }

              .author {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 700;
                margin-top: 10px;
                img {
                  height: 60px;
                  width: auto;
                  margin-right: 10px;
                  border-radius: 50%;
                  background-color: black;
                }
                span {
                  margin-left: 10px;
                  text-transform: capitalize;
                  font-weight: 400;
                }
              }
            }
          }
        }

        .body-header {
          display: flex;
          align-items: center;
          padding: 10px 0 15px;

          p {
            font-size: 18px;
            font-weight: 400;
            opacity: 0.75;
            width: calc(100% - 200px);
            padding-right: 5px;
          }

          div {
            .button {
              width: 220px;
              margin: 10px 5px;
              white-space: nowrap;

              img {
                margin-right: 5px;
              }
            }
          }
        }
      }

      .collapse-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        height: 80px;
        padding: 20px 30px;

        img {
          filter: invert(1);
          height: 20px;
          width: auto;
          transition: 0.25s ease-in-out;
        }

        .middle {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 50vw;
          font-size: 16px;
          font-weight: 700;

          div {
            margin: 0 10px;
          }

          .badge {
            background-color: var(--opposite-body);
            padding: 2.5px 10px;
            margin-right: 5px;
            border-radius: 8px;
          }
        }
      }

      h3 {
        font-size: 20px;
        font-weight: 700;
        width: 30vw;
      }
    }
  }

  .collection-summary {
    text-align: center;
    padding: 80px 0;
    h1 {
      font-size: 40px;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
      opacity: 0.75;
      font-weight: 400;
      max-width: 800px;
      margin: auto;
    }
    .mavens {
      position: relative;
      display: flex;
      overflow-x: auto;
      text-align: center;
      width: 100%;
      &::-webkit-scrollbar {
        display: none;
      }
      padding: 40px 0;

      .maven {
        position: relative;
        height: 320px;
        width: 288px;
        min-width: 288px;
        flex-basis: auto;
        margin: auto;

        .overlay {
          background: linear-gradient(
            0deg,
            black 0%,
            rgba(0, 0, 0, 0.9) 20%,
            rgba(0, 0, 0, 0.25) 30%,
            rgba(0, 0, 0, 0) 100%
          );
        }

        .button {
          width: 160px;
          margin: 10px auto;
        }

        img {
          height: 301px;
          width: 288px;
          object-fit: cover;
          padding: 20px;
        }

        .title {
          position: absolute;
          bottom: -20px;
          left: 0;
          right: 0;
          font-weight: 600;
          font-size: 24px;
        }
      }
    }
    .tabs {
      margin: 30px auto;
      justify-content: center;

      .tab {
        margin: 0;
        padding: 10px;
        min-width: 150px;
        text-align: center;
        border-color: var(--secondary);

        &.active {
          border-color: var(--primary);
        }
      }
    }
  }

  .hero {
    overflow-x: auto;
    height: 95vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;

    .flushed {
      .session-summary {
        margin-bottom: 0;
      }
    }

    .avatar {
      height: 38px;
      width: 38px;
      border-radius: 50%;
      object-fit: cover;
      margin: 0 -2.5px;
    }

    .actions {
      display: flex;
      .button {
        width: 240px;

        height: 52px;
        img {
          margin-right: 10px;
        }
      }
    }

    .session-summary {
      display: flex;
      margin-top: 0;
      height: 112px;
      transition: 0.25s ease-in-out;
      margin-bottom: 40px;

      .block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 112px;
        width: 128px;
        background: rgba(255, 255, 255, 0.2);
        //backdrop-filter: blur(50px);
        border-radius: 8px;
        font-size: 12px;
        margin-right: 15px;

        .expert-icons {
          display: flex;
          .icon {
            border-radius: 50%;
            margin: 0 -4px;
          }
        }

        img {
          height: 25px;
          width: 25px;
          object-fit: cover;
        }
      }

      h5 {
        font-weight: 500;
        font-size: 24px;
      }
    }

    .line {
      display: flex;
      margin-bottom: 15px;

      img {
        margin-right: 7.5px;
      }
    }

    .call-to-action {
      margin-top: 40px;
    }
  }

  h1 {
    font-size: 72px;
    font-weight: 800;
    line-height: 1;
  }

  p {
    font-weight: 500;
    font-size: 20px;
  }

  video {
    object-fit: cover;
    object-position: top center;
    width: 100vw;
    height: calc(95vh - 60px);
    position: absolute;
    top: 60px;
    left: 0;
    z-index: -1;
  }

  .video-controls {
    position: relative;
    position: absolute;
    bottom: 25px;
    right: 25px;
    display: flex;

    span {
      display: flex;
    }

    img {
      cursor: pointer;
      margin: 5px;
    }
  }

  .landingBgInfo {
    position: relative;
    color: var(--default);
    max-width: 680px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 7.5vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      color: var(--primary);
      margin: 0 10px;
    }

    .call-to-action {
      display: flex;
      max-width: 400px;

      input,
      .button {
        height: 52px !important;
      }
      .button {
        width: 134px;
        margin-left: 15px;
      }
    }

    p {
      margin: 20px 0 30px;
      font-weight: 400;
    }
    b {
      text-decoration: underline;
    }
  }

  .opposite-body {
    background-color: var(--opposite-body);

    .title-with-tabs {
      color: var(--background);
    }
  }

  h2 {
    font-size: 28px;
    font-weight: 700;
    color: var(--background);
    display: flex;
    flex-direction: column;
    line-height: 1.1;

    span {
      font-size: 18px;
      font-weight: 400;
    }
  }

  .session-heading {
    display: flex;
    align-items: center;
    margin: 0 0 15px 5vw;

    img {
      height: 72px;
      width: 72px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 15px;
    }
  }

  .conversations-hscroll {
    padding-left: 0 !important;
    height: fit-content !important;
    padding-bottom: 120px !important;

    h4,
    .card:first-child {
      margin-left: 5vw;
    }

    .collection-container {
      &:first-child {
        margin-left: 5vw;
      }

      .card:first-child {
        margin-left: 5px;
      }
    }
  }

  .session-content {
    display: flex;
    overflow: scroll;
    width: 100%;
    height: 400px;
    max-width: none;
    padding: 0;

    &::-webkit-scrollbar {
      display: none;
    }

    .card {
      overflow: hidden;
      position: relative;
      padding: 25px;
      border-radius: 12px;
      height: 296px;
      background-size: cover;
      background-repeat: no-repeat;
      //width: 636px;
      //min-width: 636px;
      width: 500px;
      min-width: 500px;
      margin: 0 10px 20px;

      &:first-child {
        margin-left: 5vw;
      }

      &:last-child {
        margin-right: 30px;
      }
    }
  }

  .text-banner {
    color: var(--background);
    text-align: center;
    max-width: 954px;
    margin: 0 auto 120px;
    padding-top: 120px;

    h1 {
      font-size: 54px;
      font-weight: 800;
      margin-bottom: 20px;
    }
    p {
      font-size: 20px;
    }
  }

  .courses-section {
    padding: 0;
    //padding: 40px;
    color: var(--default);
    z-index: 1;
    position: relative;

    .button {
      max-width: 268px;
      margin: 20px auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .title-with-tabs {
      margin-left: 7.5vw;
    }
  }

  .conversations-hscroll {
    background-color: var(--opposite-body);
    padding-left: 7.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 500px;

    .overlay {
      border-radius: 8px;
    }

    .card {
      overflow: hidden;
    }

    h4 {
      color: var(--background);
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .courses-section {
      .title-with-tabs h4 {
        margin-left: 0 !important;
      }
    }

    .browse-content {
      padding-bottom: 40px;
      min-height: unset;
    }
    .collection-summary {
      p {
        padding: 0 15px;
      }
    }

    .heroBgInfo {
      p {
        margin: 20px 15px 30px 0;
      }
    }

    h1 {
      font-size: 36px;
    }

    .hero .session-summary .block {
      height: 100px;
      width: 100px;
      h5 {
        font-size: 20px;
      }
    }

    h2 {
      font-size: 21px;
      span {
        font-size: 14px;
      }
    }
    .session-content {
      grid-gap: 0;

      .card {
        width: 300px;
        min-width: 300px;
      }
    }

    .text-banner {
      padding: 80px 15px 40px;
      margin-bottom: 20px;

      h1 {
        font-size: 41px;
        font-weight: 800;
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
      }
    }
    .text-banner {
      h1 {
        font-size: 31px;
      }
    }

    h4 {
      margin-left: 0 !important;
      color: var(--background);
    }
    .collapse-container {
      padding: 0;
      .collapse {
        border-radius: 0;
        margin-bottom: 1px;

        .collapse-header {
          padding: 10px 15px;
          height: 60px;
          h3 {
            width: 80%;
          }
          .middle {
            display: none;
          }
        }
        .collapse-body {
          padding: 0 15px;
          .body-header {
            padding: 10px 0;

            p {
              width: 100%;
            }
          }
          .body {
            .item {
              height: fit-content;
              flex-direction: column;
              position: relative;
              border-radius: 8px;

              .left {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                border-radius: 8px;
              }

              .right {
                z-index: 1;
                border-radius: 8px;
                color: var(--opposite);
                background: var(--modal-background);
                padding: 15px;

                h5 {
                  max-width: 100%;
                }

                .icon {
                  position: absolute;
                  right: 5px;
                  top: 5px;
                  height: 50px;
                  width: 50px;
                }
              }
            }
          }
          .body-header {
            flex-direction: column;
          }
          .body-actions {
            display: flex;
            width: 100%;
            margin-top: 20px;
            .button {
              &.primary {
                min-width: 230px;
                margin: 0;
                margin-right: 10px;
              }

              &.secondary {
                min-width: 110px;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
