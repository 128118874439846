.browse-content {
  padding-bottom: 60px;
  position: relative;
  color: var(--default);
  min-height: 800px;

  .landingBgInfo {
    position: relative;
  }

  h4 {
    font-size: 28px;
  }

  .hero {
    overflow: hidden;
    height: 45vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    text-align: center;

    .landingBgInfo {
      position: relative;
    }

    hr {
      width: 59px;
      border-width: 3px;
      border-color: var(--primary);
      margin: 30px auto;
    }

    span {
      color: var(--primary);
      font-weight: 800;
    }
  }

  p {
    font-weight: 500;
    font-size: 20px;
  }

  video {
    object-fit: cover;
    object-position: center -5vh;
    width: 100vw;
    height: calc(45vh);
    position: absolute;
    top: 60px;
    left: 0;
    z-index: -1;
  }

  h1 {
    font-size: 72px;
    font-weight: 800;
    line-height: 1;
  }

  p {
    font-weight: 500;
    font-size: 20px;
  }

  video {
    object-fit: cover;
    object-position: center -5vh;
    width: 100vw;
    height: calc(45vh);
    position: absolute;
    top: 60px;
    left: 0;
    z-index: -1;
  }

  .opposite-body {
    background-color: var(--opposite-body);
  }

  h2 {
    font-size: 28px;
    font-weight: 700;
    color: var(--background);
    display: flex;
    flex-direction: column;
    line-height: 1.1;

    span {
      font-size: 18px;
      font-weight: 400;
    }
  }

  .session-heading {
    display: flex;
    align-items: center;
    margin: 0 0 15px 5vw;

    img {
      height: 72px;
      width: 72px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 15px;
    }
  }

  .conversations-hscroll {
    padding-left: 0 !important;
    height: fit-content !important;
    padding-bottom: 120px !important;
  }

  .session-content {
    display: flex;
    overflow: scroll;
    width: 100%;
    height: 400px;

    &::-webkit-scrollbar {
      display: none;
    }

    .card {
      overflow: hidden;
      position: relative;
      padding: 25px;
      border-radius: 12px;
      height: 296px;
      background-size: cover;
      background-repeat: no-repeat;
      width: 636px;
      min-width: 636px;
      margin: 0 10px 20px;
      cursor: pointer;

      &:first-child {
        margin-left: 5vw;
      }

      &:last-child {
        margin-right: 30px;
      }

      .avatar {
        margin-right: 10px;
      }

      .play-button {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
      }

      h3 {
        font-size: 24px;
        font-weight: 800;
        margin: 10px 0;
        position: relative;
      }

      h6 {
        position: absolute;
        bottom: 20px;
        left: 25px;
      }

      &:hover {
        .content {
          opacity: 1;
        }
        .play-button,
        h6 {
          opacity: 0;
        }
        .overlay {
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.6) 80%,
            rgba(0, 0, 0, 0) 100%
          );
        }
      }

      .content {
        position: relative;
        opacity: 0;
        transition: 0.25s ease-in-out;

        .button {
          margin: 20px 0;
          max-width: 204px;
          height: 46px;

          img {
            filter: invert(1);
            margin-right: 10px;
            height: 18px;
          }
        }

        p {
          font-size: 14px;
          margin: 5px 0;
        }
      }

      .header {
        display: flex;
        font-size: 16px;
        position: relative;

        .avatar {
          height: 38px;
          width: 38px;
          border-radius: 50%;
          object-fit: cover;
        }

        .meta {
          font-size: 12px;
        }
      }
    }
  }

  .collections-section {
    padding: 60px 0 0;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h4 {
      margin: 0 0 25px 7.5vw;

      font-weight: 800;
    }

    .button {
      max-width: 268px;
      margin: 40px auto 20px;
    }

    .card-collection {
      display: flex;
      width: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }

      a {
        &:first-child {
          margin-left: 7.5vw;
        }

        .card {
          &:first-child {
            margin-left: 0;
          }
        }
      }

      .card {
        height: 299px;
        width: 642px;
        border-radius: 8px;
        min-width: 642px;
        position: relative; //allows for horizontal scroll
        margin-right: 20px;
        overflow: hidden;

        .overlay {
          background: linear-gradient(
            120deg,
            rgba(0, 0, 0, 0.9) 0%,
            rgba(0, 0, 0, 0.6) 40%,
            rgba(0, 0, 0, 0) 60%
          );
        }

        .content {
          position: relative;
          padding: 20px;

          h5 {
            font-size: 24px;
            font-weight: 800;
          }
        }
      }
    }
  }

  .courses-section {
    padding-bottom: 60px;

    @media screen and (max-width: 768px) {
      padding-bottom: 40px;
    }
  }

  .conversations-hscroll {
    background-color: var(--opposite-body);
    padding-left: 7.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 500px;

    .overlay {
      border-radius: 8px;
    }

    .card {
      overflow: hidden;
    }

    h4 {
      color: var(--background);
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    h4,
    .card:first-child {
      margin-left: 5vw;
    }

    .collection-container {
      &:first-child {
        margin-left: 5vw;
      }

      .card:first-child {
        margin-left: 5px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .hero {
      padding: 20px;
    }

    .session-content {
      .card {
        min-width: 300px;
        width: 300px;
      }
    }
    .collections-section {
      margin: 0;
      .card-collection a .card {
        width: 380px;
        min-width: 380px;
        height: 300px;
        background-position: center;
      }
      h1 {
        font-size: 46px;
        margin-bottom: 20px;
      }

      p {
        font-size: 16px;
      }
    }
  }
}
