.card-stack {
  &::-webkit-scrollbar {
    display: none;
  }

  .microphone {
    opacity: 0;
  }

  &.Conversation {
    .meta {
      b {
        margin-right: 3px;
      }
    }
  }

  .icon {
    margin: 10px auto;
    height: 40px;
    width: 40px;
  }
  .card-collection {
    position: relative;
    display: flex;
    z-index: 2;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    .collection-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 20px;

      .title {
        //font-size: 40px;
        font-size: 34px;
        font-weight: 800;
        line-height: 1.1;
        margin-bottom: 0;
      }

      .card {
        cursor: pointer;
        height: 539px;
        width: 301px;
        background-size: cover;
        border-radius: 8px;
        margin-right: 20px;
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        flex-direction: column;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 40px;
        color: var(--default);
        overflow: hidden;
        border: 2px solid var(--background);
        //background-color: var(--backgroundSecondary);

        @media screen and (max-width: 738px) {
          height: 458px;
          width: 256px;

          .actions {
            display: none;
          }
          .meta {
            font-size: 14px;
            padding-top: 5px;

            span {
              display: none;
            }
          }
          .title {
            font-size: 28px;
          }
        }

        video {
          position: absolute;
          height: 539px;
          width: 301px;
          border-radius: 8px;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          object-position: center;
        }

        .actions {
          opacity: 0;
          width: calc(100% - 60px);
          transition: 0.25s ease-in-out;
          height: 0;

          .primary,
          .secondary,
          img {
            display: none;
          }
        }

        &:hover {
          .actions {
            margin: 20px auto 120px;
            width: 100%;
            opacity: 1;
            text-align: center;

            .primary,
            .secondary {
              height: 52px;
              width: 232px;
              margin: auto;
              display: flex;
              margin-bottom: 20px;

              img {
                display: block;
                margin-right: 5px;
              }
            }
          }
        }

        &.is-ask {
          justify-content: flex-start;

          .overlay {
            background: none;
          }
          .card-content {
            display: none !important;
          }

          .ask {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            z-index: 2;
            //padding: 15px;
            text-align: center;
            height: 100%;
            padding: 25px 0 0;

            h4.title {
              font-size: 32px;
            }

            img {
              height: 120px;
              width: 120px;
              border-radius: 50%;
              position: relative;
              background-color: black;
              margin: 10px auto 20px;
            }

            p {
              font-size: 14px;
            }

            h5 {
              font-size: 58px;
              font-weight: 700;
              margin-bottom: -5px;
              margin-top: -20px;
            }

            .button {
              width: 200px;
              /* position: absolute;
              bottom: 20px; */
              border: 1px solid var(--modal-background);
              font-size: 16px;
              height: 49px;
              margin: 0;
            }

            &:hover {
              .actions {
                display: none;
              }
            }
          }

          @media screen and (max-width: 768px) {
            .ask {
              img {
                height: 80px;
                width: 80px;
              }
              h4.title {
                font-size: 22px;
              }
              h5 {
                font-size: 38px;
              }
              .button {
                width: 160px;
              }
            }
          }
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.9) 0%,
            rgba(0, 0, 0, 0.6) 40%,
            rgba(0, 0, 0, 0) 60%
          );
          width: 102%;
          height: 102%;
        }

        .card-content {
          padding: 10px 15px 0;
          position: relative;
          text-align: center;
          z-index: 2;

          .meta {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            font-size: 14px;

            img {
              height: 20px;
              width: 20px;
              margin-right: 5px;
              margin-top: 1px;
            }

            div {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .card-stack {
    &.Conversation {
      .meta {
        color: rgba(255, 255, 255, 0.5);
        img {
          display: none;
        }
        b {
          color: rgba(255, 255, 255, 1);
          display: contents;
          font-size: 16px;
          line-height: 1;
          margin: 0;
        }
      }
    }
    .card-collection {
      .collection-container {
        .card {
          padding-bottom: 20px;
          height: 377px;
          width: 211px;
        }
      }
    }
  }
}
