.card-grid {
  &::-webkit-scrollbar {
    display: none;
  }
  .microphone {
    filter: invert(1);
  }

  &.Conversations {
    .meta {
      b {
        margin-right: 3px;
      }
    }
  }

  .clickable {
    height: 60%;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    cursor: pointer;
  }
  .card-collection {
    position: relative;
    z-index: 2;
    overflow-x: auto;
    margin: 0 -0.5vw;
    display: grid;
    //grid-template-columns: repeat(4, 1fr);
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-auto-rows: auto;
    //grid-gap: 1rem;

    .collection-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 20px;

      .title {
        font-size: 34px;
        font-weight: 800;
        line-height: 1.1;
        margin-bottom: 0;
      }

      .card {
        height: 539px;
        //width: calc(100vw / 4);
        background-size: cover;
        background-position: top center;
        border-radius: 8px;
        margin: 10px;
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        flex-direction: column;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 40px;
        overflow: hidden;
        border: 2px solid var(--background);

        video {
          position: absolute;
          height: 100%;
          width: 100%;
          border-radius: 8px;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          object-position: center;
        }

        .actions {
          opacity: 0;
          width: calc(100% - 60px);
          transition: 0.25s ease-in-out;
          height: 0;

          .primary,
          .secondary,
          img {
            display: none;
          }
        }

        &:hover {
          .actions {
            margin: 20px auto 120px;
            width: 100%;
            opacity: 1;
            text-align: center;

            .primary,
            .secondary {
              height: 52px;
              width: 232px;
              margin: auto;
              display: flex;
              margin-bottom: 20px;

              img {
                display: block;
                margin-right: 5px;
              }
            }
          }
        }

        .overlay {
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.9) 0%,
            rgba(0, 0, 0, 0.6) 40%,
            rgba(0, 0, 0, 0) 60%
          );
          z-index: 1;
          width: 102%;
          height: 102%;
        }

        .card-content {
          padding: 10px 15px 0;
          position: relative;
          text-align: center;
          z-index: 2;

          .meta {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            font-size: 14px;

            img {
              height: 20px;
              width: 20px;
              margin-right: 5px;
              margin-top: 1px;
            }

            div {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .card-grid {
    &.Conversations {
      .meta {
        color: rgba(255, 255, 255, 0.5);
        img {
          display: none;
        }
        b {
          color: rgba(255, 255, 255, 1);
          display: contents;
          font-size: 16px;
          line-height: 1;
          margin: 0;
        }
      }
    }
    .card-collection {
      padding-bottom: 40px;
      margin: 0;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      grid-auto-rows: auto;

      .collection-container {
        .card {
          height: 339px;
          margin: 0;
          padding-bottom: 15px;

          .title {
            font-size: 24px;
          }

          .meta {
            span {
              display: none;
            }
          }

          &:hover {
            .actions {
              display: none;
            }
            .overlay {
              background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.9) 0%,
                rgba(0, 0, 0, 0.6) 60%,
                rgba(0, 0, 0, 0) 100%
              );
            }
          }
        }
      }
    }
  }
}
