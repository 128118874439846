.login-page {
  background: #000;
  overflow-x: auto;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;

  h3 {
    font-size: 46px;
    font-weight: 700;
    line-height: 1.3;
    &:last-of-type {
      color: var(--primary);
    }
  }

  p {
    font-weight: 500;
    font-size: 17px;
  }

  .left {
    width: 45vw;
    padding: 0 15px;
    margin-top: 64px;
  }

  .show-mobile {
    display: none;
  }

  video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .loginBg {
    background: none !important;
    .collab-container {
      display: flex;
      max-width: 600px;
      margin: 0 auto;

      .collab {
        font-size: 3em;
        color: white;
        z-index: 1;
        margin: 0 20px;
      }

      img {
        margin-bottom: 20px;
        width: 80px;
        height: 80px;
        height: auto;
        display: block;
        object-fit: contain;
        position: relative;
      }
    }

    .loginBgInfo {
      position: relative;
      color: #fff;
      margin: 0 auto;
      max-width: 600px;

      p {
        margin: 10px 0;
        font-weight: 400;
      }
      b {
        text-decoration: underline;
      }
    }
    .loginBgOverlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.25) 100%
      );
    }
  }
  .loginInfoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .loginInfo {
      //margin-top: 20px;
      background-color: rgba(0, 0, 0, 1);
      padding: 25px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      //position: relative;
      width: 25vw;
      min-width: 380px;
      h2 {
        margin-bottom: 60px;
        font-size: 1.75em;
        font-weight: 800;
        color: #fff;
        line-height: 1;
        margin-bottom: 0.5em;
      }
      .loginData {
        width: 100%;
        .loginRow {
          &:not(:first-of-type) {
            margin: 18px 0 0 0;
          }
          p {
            margin: 10px 0 0 0;
            color: red;
            text-align: left;
          }
          input {
            height: 42px;

            color: var(--background);
            border-radius: 8px;
          }
        }
      }
      .signupWrapper {
        .checkboxWrapper {
          padding: 10px 0 5px 0;
          display: flex;

          p {
            margin-left: 16px;
            color: #9e9e9e;
            font-weight: 400;
            line-height: 1.5;
            font-size: 14px;
            a {
              text-decoration: underline;
            }
          }
          input {
            margin: 4px 0 0 0;
          }
        }
        .errorMsg {
          p {
            margin: 10px 0 0 0;
            font-weight: 500;
            font-size: 17px;
            color: red;
            text-align: left;
          }
        }
      }
      .btnWrapper {
        margin: 10px 0 20px 0;
        width: 100%;
        button {
          width: 100%;
          height: 42px;
          background: var(--primary);
          color: #000;
          font-size: 16px;
        }
      }
      .additionalInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
        text-align: left;

        p {
          margin-bottom: 3px;
          font-size: 18px;
          font-weight: 600;
          color: #fff;
        }
        span {
          color: #9e9e9e;
          font-size: 14px;
          font-weight: 400;
          &.signUpText,
          &.signInText,
          &.resetText {
            cursor: pointer;
            color: #016fd0;
            font-weight: 500;
          }
        }
      }
    }
  }

  &.login-modal-override {
    background: none !important;

    .close-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 768px) {
  .login-page {
    background-position: top center !important;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;

    .show-mobile {
      display: block;
    }

    .loginInfoWrapper {
      .loginInfo {
        min-width: 340px;
      }
    }

    video {
      display: none;
    }

    .loginInfoWrapper .loginInfo {
      padding: 0px;
      background: none;
    }

    .checkboxWrapper {
      p {
        text-align: left;
      }
    }

    .loginBg {
      /* background: inherit !important;
      justify-content: center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important; */

      .loginBgOverlay {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.6) 85%,
          rgba(0, 0, 0, 0.25) 100%
        );
      }
    }

    .left {
      width: 100vw;
      padding: 0 15px;

      .collab-container {
        justify-content: center;
        padding-top: 20px;
      }
    }

    h3 {
      font-size: 26px;
    }

    p {
      font-size: 14px;
    }

    .collab {
      font-size: 2.5em;
      color: white;
      margin: 0 15px;
      opacity: 0.5;
    }
    .logo {
      height: 60px;
      width: 60px;
      object-fit: contain;
    }
    .loginInfoWrapper {
      padding: 0 20px;
      align-items: flex-end;
      .loginInfo {
        margin-bottom: 10px;
        min-width: 320px;
      }
    }
  }
}
