.subscribe {
  text-align: center;
  flex-direction: column;
  text-align: center;

  .logo {
    height: 72px;
    width: 72px;
  }

  h3 {
    color: var(--primary);
    font-weight: 800;
    font-size: 32px;
    margin-bottom: 20px;
  }

  .close {
    position: absolute;
    top: 25px;
    right: 25px;
    height: 25px;
    opacity: 0.75;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .content {
    img {
      height: 72px;
      margin: 0 auto 20px;
    }
    max-width: 720px;
    margin: auto;

    h1 {
      font-size: 46px;
      font-weight: 800;
      line-height: 54px;
      margin-bottom: 30px;
    }

    p {
      opacity: 0.85;
      font-size: 20px;
      margin-bottom: 40px;
    }

    .button {
      width: 200px;
      margin: auto;
    }
  }

  @media screen and (max-width: 767px) {
    .content {
      h1 {
        font-size: 24px;
        line-height: 1.2;
      }
      h3 {
        font-size: 21px;
      }
    }
  }
}
