.policies {
  .page {
    padding: 10vh 5vw;
    color: var(--opposite-body);

    h1 {
      font-size: 46px;
      color: var(--opposite-body);
      font-weight: 800;
    }

    a {
      color: var(--primary);
    }
  }
}
