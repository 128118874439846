.article-landing {
  h4 {
    margin-bottom: 20px;
    text-transform: capitalize;
  }
  .filters {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    background: linear-gradient(125.25deg, #535353 3.46%, #000000 100%);
    color: var(--default);
    margin-bottom: 60px;

    &::-webkit-scrollbar {
      display: none;
    }

    div {
      &:first-child {
        margin-left: auto;
      }
      &:last-child {
        margin-right: auto;
      }
      padding: 10px 15px;
      text-transform: capitalize;
      cursor: pointer;
      opacity: 0.5;
      font-weight: 500;
      border-bottom: 3px solid var(--backgroundSecondary);

      &.active {
        border-bottom: 3px solid var(--primary);
        opacity: 1;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
  .latest-publications {
    padding: 64px 0 120px;
    .publication-list {
      .collection {
        overflow-x: auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        grid-auto-rows: auto;
        grid-gap: 1rem;

        .card {
          width: inherit;
        }
      }
    }
  }
}
