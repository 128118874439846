@font-face {
  font-family: 'VSfont';
  src: local('HelveticaNowLight'),
    url('../fonts/HelveticaNowText-Thin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'VSfont';
  src: local('HelveticaNowRegular'),
    url('../fonts/HelveticaNowText-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'VSfont';
  src: local('HelveticaNowMedium'),
    url('../fonts/HelveticaNowText-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'VSfont';
  src: local('HelveticaNowBold'),
    url('../fonts/HelveticaNowText-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'VSfont';
  src: local('HelveticaNowExtraBold'),
    url('../fonts/HelveticaNowText-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'VSfont';
  src: local('HelveticaNowBold'),
    url('../fonts/HelveticaNowText-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;font-family: 'VSfont', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
  text-align: center;
}

/*
  HOME Page
 */
#home-hero {
  background-image: url('/public/HomeHero.jpg');
  background-size: cover;
}

.carousel-item.trailers {
  min-width: 46%;
}

.carousel-item.roster {
  min-width: 20rem;
}

.carousel-item::before {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 65%,
    rgba(0, 0, 0, 0.76) 100%
  );
  content: ' ';
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}

#footer {
  background-image: url('/public/BGFooterLogo.png');
  background-repeat: no-repeat;
  background-size: cover;
}

#sports-hero {
  background-image: url('/public/BGSportsHero.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.mega-dropdown {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(28px);
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 4;
  font-size: 56px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 40px;
}

.mega-dropdown a {
  opacity: 0.5;
}

.mega-dropdown a:hover {
  opacity: 1;
}

