.searchbar {
  background-color: var(--form-background);
  border-radius: 4px;
  height: 56px;
  display: flex;
  position: relative;
  padding: 0 50px;

  .search {
    position: absolute;
    top: 16px;
    left: 15px;
    opacity: 0.5;
  }

  input {
    background-color: transparent;
    width: 100%;
  }
}
