.interactive-page {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  position: relative;
  background-color: rgb(34, 27, 34);
  height: calc(100vh - 30px);
  overflow: hidden;

  *:not(input):not(textarea) {
    -webkit-user-select: none; /* disable selection/Copy of UIWebView */
    -webkit-touch-callout: none; /* disable the IOS popup when long-press on a link */
  }

  .speech-mobile {
    top: 0px;
  }

  .microphone-mobile {
    height: 64px;
    width: 64px;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  .actions-tab {
    display: none;
  }

  .speak-button {
    position: relative;
    margin: auto;
    d img {
      z-index: 1;
    }
  }

  .pulsating-circle {
    position: absolute;
    left: 50%;
    top: 48%;
    transform: translateX(-50%) translateY(-50%);
    width: 160px;
    height: 160px;

    &:before {
      content: '';
      position: relative;
      display: block;
      width: 160px;
      height: 160px;
      box-sizing: border-box;
      margin-left: 0;
      margin-top: 0;
      border-radius: 100%;
      background-color: var(--primary);
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }

    /* &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100px;
      height: 100px;
      background-color: white;
      border-radius: 100%;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
    } */
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }

  @keyframes pulse-dot {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }

  @keyframes pulse-dot {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }

  .speech {
    position: absolute;
    bottom: 140px;
    left: 0;
    right: 0;
    text-align: center;
    background: var(--secondary-faded);
    padding: 5px;
    border-radius: 5px;
  }

  .video-container {
    position: relative;
    height: calc(100vh - 30px);
    video {
      position: absolute;
      top: 30px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      height: calc(100vh - 30px);
    }

    .overlay {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.9) 20%,
        rgba(0, 0, 0, 0.2) 30%,
        rgba(0, 0, 0, 0) 100%
      );
      z-index: 0;
    }
  }

  h5 {
    font-size: 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    margin-bottom: -5vh;
    opacity: 0.5;
    text-align: center;
    bottom: 0;
  }

  .topics {
    position: absolute;
    right: 20px;
    bottom: 80px;
    width: 320px;
    background: linear-gradient(
      125.25deg,
      rgba(255, 255, 255, 0.16) 3.46%,
      rgba(255, 255, 255, 0) 100%
    );
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 8px;

    &.selected-topic {
      .body {
        display: block;
      }

      .item {
        justify-content: flex-start;
        height: fit-content;
        margin-bottom: 10px;
      }
    }

    .header {
      padding: 10px;
      background: linear-gradient(
        125.25deg,
        rgba(255, 255, 255, 0.16) 3.46%,
        rgba(255, 255, 255, 0) 100%
      );
      border-radius: 8px 8px 0px 0px;
      font-size: 16px;
      font-weight: 700;
      text-align: center;

      img.icon {
        height: 15px;
        position: absolute;

        opacity: 0.5;

        &.close {
          right: 15px;
          top: 15px;
        }

        &.back {
          left: 15px;
          top: 15px;
          transform: rotate(90deg);
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .body {
      padding: 5px 10px;
      display: flex;
      overflow: auto;
      flex-direction: row;
      height: 500px;
      width: 100%;
      flex-wrap: wrap;
    }

    .item {
      height: 60px;
      box-sizing: border-box;
      width: 100%;
      background: linear-gradient(
        125.25deg,
        rgba(255, 255, 255, 0.16) 3.46%,
        rgba(255, 255, 255, 0) 100%
      );
      border-radius: 8px;
      margin: 5px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;
      font-size: 14px;
      line-height: 1.25;
      cursor: pointer;
      //transition: 0.25s ease-in-out;
      opacity: 0.85;
      position: relative;

      &:hover {
        opacity: 1;
      }

      h6 {
        font-size: 18px;
        font-weight: 500;
      }

      img.icon {
        transform: rotate(-90deg);
        height: 15px;
        width: auto;
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }

  input {
    height: 60px;
    color: var(--background);
    padding: 10px 20px;
    margin-bottom: 15px;
  }

  .input-container {
    position: relative;

    img {
      position: absolute;
      right: 15px;
      top: 18px;
      height: 25px;
      width: 25px;
      object-fit: contain;
      opacity: 0.75;

      &:hover {
        opacity: 1;
        filter: invert(0);
      }
    }
  }

  .actions {
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;

    .faded.toggle {
      width: 110px;
      text-align: center;
    }

    div {
      &.left,
      &.group,
      &.middle {
        padding: 20px 40px;
      }
      img {
        margin: 0 auto 5px;
        &:hover {
          cursor: pointer;
        }
      }
      &.left {
        img {
          width: 48px;
          height: 48px;
        }
      }

      &.middle {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 560px;
        margin: auto;
        text-align: center;

        b {
          color: var(--primary);
          opacity: 1;
        }

        img {
          &:hover {
            filter: invert(1);
          }
        }
      }
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &.group {
        flex-direction: row;
        align-items: flex-end;
        position: relative;

        img {
          height: 49px;
          &:last-child {
            margin-left: 15px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    background-size: cover;

    .input-container {
      position: absolute;
      bottom: 16vh;
      left: 15px;
      right: 15px;
    }

    .speech {
      bottom: 28vh;
      left: 5vw;
      right: 5vw;
    }

    .speak-button {
      position: absolute;
      bottom: 17.5vh;
      left: 0;
      right: 0;
      margin: auto;
      .microphone-mobile {
        margin: auto;
      }
    }

    .actions {
      display: none;
    }

    .video-container {
      video {
        height: 100vh;
        top: 20px;
        object-position: center;
      }
    }

    .actions-tab {
      position: absolute;
      bottom: 90px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;

      &.toggled {
        .item.tab-item {
          &.active {
            border-color: transparent;
          }
          .tab-title {
            display: none;
          }
        }
      }

      .tab-item {
        width: 90px;
      }

      .topics {
        left: unset;
        margin-top: -75vh;
        top: 0;
        bottom: 0px;
        position: relative;
        background-color: rgba(0, 0, 0, 0.9);
        overflow: hidden;

        &::-webkit-scrollbar {
          display: none;
        }

        .body {
          padding: 10px;
          position: relative;
          height: 73vh;
          .item {
            text-align: left;
            position: relative;
            margin-bottom: 10px;
            opacity: 1;

            h6 {
              position: relative;
            }

            &:last-child {
              margin-bottom: 20px;
            }
          }
        }
      }

      .item {
        border-bottom: 2px solid transparent;
        //width: 80px;
        text-align: center;
        margin: 0 5px;
        transition: 0.25s ease-in-out;
        opacity: 0.5;
        font-weight: 700;
        position: relative;

        &.active {
          border-color: var(--primary);
          opacity: 1;
        }
      }

      .item.tab-item {
        padding: 5px 15px;
        margin: 0 10px;

        &.active {
          border-bottom: 2px solid var(--primary);
        }

        .topics {
          position: absolute;
          right: -5vw;
          left: unset;
        }
      }
    }
  }
}
