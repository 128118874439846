.bio-modal {
  flex-direction: column;

  .header {
    height: 64px;
    background-color: var(--backgroundSecondary);
    width: 100%;
    max-width: 934px;
    margin: 0 auto;
    border-radius: 12px 12px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    position: relative;
  }

  .content {
    display: flex;
    max-width: 934px;
    background-color: var(--background);
    padding: 20px;
    border-radius: 0 0 12px 12px;

    h3 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }

  .left {
    width: 30%;
  }

  .right {
    width: 70%;
    padding: 2.5vw;
  }

  .left,
  .right {
    img {
      border-radius: 50%;
    }

    p {
      margin-bottom: 10px;
    }
  }

  .right {
    max-height: 50vh;
    overflow: auto;
    /* &::-webkit-scrollbar {
      display: none;
    } */
  }

  .close {
    position: absolute;
    top: 25px;
    right: 25px;
    height: 25px;
    opacity: 0.75;
    cursor: pointer;
    height: unset;
    width: unset;

    &:hover {
      opacity: 1;
    }
  }

  img {
    width: 304px;
    height: auto;
    border-radius: 12px;
    object-fit: cover;
    object-position: center;
  }

  @media screen and (max-width: 768px) {
    padding: 15px;

    .content {
      flex-direction: column;
      .left,
      .right,
      img {
        width: 100%;
        height: 300px;
      }
      .left {
        margin-bottom: 20px;
      }
    }
  }
}
