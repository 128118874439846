.subscription {
  .settingsRow {
    padding: 90px 0 0 0;
    > h4 {
      margin-bottom: 36px;
      font-size: 24px;
      font-weight: 700;
      line-height: 1;
      color: #fff;
    }
    .subscriptionBox {
      width: 100%;
      height: 152px;
      padding: 24px;
      background: #222;
      box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.08);
      backdrop-filter: blur(200px);
      border-radius: 8px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .subscriptionInfoWrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        .subscriptionInfo {
          h4 {
            font-size: 24px;
            font-weight: 400;
            color: #fff;
            line-height: 1;
          }
          p {
            padding: 4px 0 0 0;
            font-size: 14px;
            font-weight: 400;
            color: #fff;
          }
        }
        .subscriptionRenewal {
          span {
            font-size: 14px;
            font-weight: 400;
            color: rgba(24, 26, 28, 0.4);
          }
        }
      }
      .changePlanBtn {
        width: 182px;
        height: 40px;
        background: #3a3a3a;
        font-size: 16px;
      }
    }
  }
  @media (max-width: 600px) {
    .settingsRow {
      padding: 50px 0 0 0;
      .subscriptionBox {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        .changePlanBtn,
        .button {
          margin: 6px 0 0 0;
        }
      }
    }
  }
}
