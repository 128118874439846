.alert {
  background-color: var(--modal-background);
  color: var(--opposite);
  padding: 7.5px 20px;
  border-radius: 2.5px;
  position: absolute;
  top: 80px;
  right: 15px;
  z-index: 999992;
  transition: 0.25s ease-in-out;
  display: flex;

  img {
    margin-right: 5px;
  }

  &.success {
    background-color: var(--success);
    color: var(--background);
    img {
      filter: invert(1);
    }
  }
}
