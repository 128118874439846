.tabbed-nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 60px;
  color: var(--opposite-body-hover);
  background-color: var(--backgroundSecondary);
  font-size: 10px;
  padding-bottom: 10px;

  .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 20%;
    opacity: 0.5;

    img {
      height: 25px;
      width: auto;
    }
    &.active {
      color: var(--default);
      opacity: 1;
    }
    &.Home {
      img {
        padding: 3px 3px 1px;
      }
    }

    &.Courses {
      img {
        filter: invert(1);
      }
    }
  }
}
