.modals {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(88px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  z-index: 99992;
  &::-webkit-scrollbar {
    display: none;
  }

  .content {
    max-height: 80vh;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    justify-content: flex-start !important;

    &.login-modal {
      .content {
        padding: 0;

        .loginInfo {
          min-width: 340px;

          .close-icon {
            height: 25px;
          }
        }

        h2 {
          margin-top: 64px;
        }
      }
    }

    .close {
      top: 15px !important;
      right: 15px !important;
    }

    .close-icon {
      height: 25px;
      width: auto;
    }

    .logo {
      position: fixed;
      top: 15px;
      left: 15px;
      height: 32px !important;
      width: auto;
      z-index: 3;
    }

    .content {
      padding: 15px;
      height: inherit !important;
      width: 100% !important;
    }

    /* h1 {
      font-size: 40px !important;
      line-height: 1.25 !important;
    } */

    p {
      font-size: 16px !important;
    }

    .button-group {
      flex-direction: column;
    }
  }
}
