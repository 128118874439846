.reset-password {
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  .changePasswordModal {
    padding: 80px 0 0 0;
    width: 100%;
    max-width: 640px;
    /* min-height: 630px; */
    min-height: 560px;
    background: var(--background);
    color: var(--opposite);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    .closeBtn {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 30px;
      cursor: pointer;
    }
    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 1;
    }
    .subtitle {
      margin: 16px 0 0 0;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
    }
    .inputContainer {
      color: #000;
      position: relative;
      margin: 26px 0 0 0;
      height: 54px;
      width: 336px;
      background: #fff;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 8px;
      overflow: hidden;
      .labelInput {
        font-size: 12px;
        color: #888888;
        position: absolute;
        top: 8px;
        left: 16px;
        transition: ease-in-out 0.4s;
        &.hidden {
          opacity: 0;
        }
        &.show {
          opacity: 1;
        }
      }
      input {
        width: calc(100% - 50px);
        height: 100%;
        border: none;
        &:focus {
          border: none;
          outline: none;
          box-shadow: none;
        }
      }
      .showPasswordIcon {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        z-index: 10;
        cursor: pointer;
      }
    }
    .textError {
      width: 336px;
      margin: 10px 0;
      color: red;
      font-size: 14px;
    }
    .regexContainer {
      width: 336px;
      .regexRow {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &:not(:first-of-type) {
          margin: 12px 0 0 0;
        }
        span {
          margin-left: 14px;
        }
        .regexStatus {
          width: 20px;
          height: 20px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 12px;
            height: 12px;
            color: #fff;
          }
          &.bg-active {
            background: rgb(21, 128, 61);
          }
          &.bg-inactive {
            background: rgb(156, 163, 175);
          }
        }
      }
    }
    .btnContainer {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0 20px;
      background: var(--backgroundSecondary);
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        width: 122px;
        height: 52px;
        font-size: 16px;
        &.btnCancel {
          margin-right: 12px;
          background: transparent;
          color: #000;
        }
        &.btnDone {
          color: #888;
          &.bg-active {
            background: #fff200;
            color: #000;
          }
          &.bg-inactive {
            background: rgb(209, 213, 209);
          }
        }
      }
    }
  }
  @media (max-width: 694px) {
    .changePasswordModal {
      padding: 70px 17px 0 17px;
      max-width: 94%;
      /* min-height: 600px; */
      min-height: 540px;
      .closeBtn {
        top: 17px;
        right: 17px;
        width: 24px;
      }
      .title {
        font-size: 22px;
      }
      .inputContainer {
        width: 100%;
      }
      .textError {
        width: 100%;
      }
      .regexContainer {
        width: 100%;
      }
    }
  }
}
