.loader-container {
  padding: 20px;
  width: 100%;
  min-height: 100vh;
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  &.naked {
    background-color: transparent;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: unset;

    .loader {
      width: 60px;
      height: 60px;
    }

    .loaderInner {
      width: 20px;
      height: 20px;
      top: calc(50% - 10px);
      left: calc(50% - 10px);
    }

    .loaderOuter,
    .loaderInner {
      border-color: #fff;
    }
  }

  .loaderMessage {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    img {
      width: 40px;
      filter: brightness(1);
      margin-bottom: 20px;
    }
    p {
      margin: 34px 0 0 0;
      color: rgba(255, 255, 255, 0.85);
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      font-weight: 600;
    }
  }
  .loader {
    width: 80px;
    height: 80px;
    position: absolute;
    margin-bottom: 130px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid var(--primary);
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
