.tabs {
  display: flex;
  .tab {
    padding: 2.5px;
    margin: 0 20px;
    font-weight: 600;
    border-bottom: 2px solid transparent;
    transition: 0.25s ease-in-out;

    cursor: pointer;
    &.active {
      border-bottom: 2px solid var(--primary);
    }
  }

  @media screen and (max-width: 738px) {
    .tab {
      margin: 0 10px;
    }
  }
}
