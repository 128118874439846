button,
.button {
  padding: 15px;
  border: none;
  outline: none;
  line-height: 1;
  width: 84px;
  height: 42px;
  font-weight: 500;
  color: var(--default);
  border-radius: 8px;
  width: 100%;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.small {
    padding: 5px 15px;
    height: 30px;
  }

  &.disabled,
  &disabled,
  &[disabled='disabled'] {
    cursor: not-allowed !important;
  }

  &.primary {
    color: var(--background) !important;
    background: var(--primary);
    &:hover {
      background-color: var(--primary-hover);
      color: var(--background);
    }
  }

  &.danger {
    color: var(--default) !important;
    background: var(--danger);
    &:hover {
      opacity: 0.9;
    }
  }

  &.secondary {
    background-color: var(--secondary) !important;
    color: var(--default) !important;

    &:hover {
      background-color: var(--secondary-hover) !important;
    }
  }

  &.outlined {
    background-color: transparent;
    border: 1px solid var(--default);
    color: var(--default);

    &:hover {
      background-color: var(--default);
      color: black;
      img {
        filter: invert(1);
      }
    }
  }

  &.success {
    background-color: var(--success);
    color: var(--background);
  }

  &.rounded {
    border-radius: 21px;
  }
}

.link {
  background: none !important;
  color: var(--default) !important;
  opacity: 0.75;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;

  img {
    margin: 0 7.5px 0 0 !important;
    height: 20px;
    width: 20px;
  }

  &:hover {
    opacity: 1;
  }
}
