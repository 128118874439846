.landing-partner {
  padding-bottom: 0;
  .simple-hero,
  .text-hero {
    padding: 80px 5vw;
    h1 {
      font-size: 36px;
      max-width: 800px;
    }
  }

  @media screen and (max-width: 768px) {
    .text-hero {
      margin-top: 0;
      padding-top: 140px;
    }
    .simple-hero {
      margin: 60px 0;
    }
  }
}
