* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'VSfont';
}

:root {
  --background: black;
  --backgroundSecondary: #161616;
  --primary: #fff200;
  --primary-hover: #ffe600;
  --callout: #0088ff;
  --success: #6be48d;
  --danger: #ff2323;
  --default: #fff;
  --secondary: #3e3d40;
  --secondary-hover: #545357;
  --secondary-faded: rgb(25, 24, 26, 0.75);
  --opposite: #f5f5f5;
  --opposite-body: #f8f8f8;
  --opposite-highlight: #ffffff;
  --opposite-body-hover: rgba(208, 208, 208, 0.65);
  --form-background: #404040;
  --modal-background: rgba(53, 53, 53, 0.9);
}

[data-theme='light'] {
  --background: #f5f5f5;
  --backgroundSecondary: #f8f8f8;
  --primary: #fff200;
  --primary-hover: #ffe600;
  --callout: #0088ff;
  --success: #6be48d;
  --danger: #ff2323;
  --default: black;
  --secondary: #3e3d40;
  --secondary-hover: #545357;
  --secondary-faded: rgb(25, 24, 26, 0.75);
  --opposite: black;
  --opposite-body: #161616;
  --opposite-highlight: #ffffff;
  --opposite-body-hover: rgba(208, 208, 208, 0.65);
  --form-background: #404040;
  --modal-background: rgba(53, 53, 53, 0.9);

  nav {
    img {
      filter: invert(1);
    }
  }
}

.main-container {
  background-color: var(--background);
}

input:checked {
  //color: var(--primary) !important;
  filter: invert(1);
}

.with-banner {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 80px;
  position: fixed;

  .download-cta {
    background-color: #e5e5e5;
    height: 80px;
    padding: 7.5px 15px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;

    font-size: 18px;

    .view {
      width: 60px;
      padding-right: 10px;
      text-align: right;
      color: #3087f9 !important;
      font-size: 18px;
    }

    img {
      text-align: center;
      margin: auto;
      height: 80px;

      &.close {
        height: 20px;
        filter: invert(1);
        text-align: left;
        margin-left: 5px;
      }
    }
  }
}

.download-cta {
  display: none;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.3) 40%,
    rgba(0, 0, 0, 0) 100%
  );
}

.overlay-left {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

input {
  border: 0;
  outline: 0;

  &:focus {
    outline: none !important;
  }
}

.invert {
  filter: invert(1);
}

body {
  color: var(--default);
  background-color: var(--background);
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.faded {
  opacity: 0.5;
}

.bg-modal {
  background: rgba($color: #000000, $alpha: 0.4);
}

.bg-linder-btn {
  background: var(--primary);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.card-preview:hover {
  > span {
    color: #000000 !important;
    transition: ease-in-out 0.2s;
  }
  > svg {
    opacity: 1;
    transition: ease-in-out 0.2s;
  }
}

.bg-modal-card {
  background: linear-gradient(125.25deg, #535353 3.46%, #000000 100%);
}

.cta.primary {
  background: #fff200;
  border-radius: 8px;
  border: none;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  margin: 20px 0 0 0;
  padding: 1.25rem 2rem;
  text-align: center;
  outline: none;
  width: 100%;
}

.faq {
  background-color: var(--opposite);
  padding: 20px 0 120px;
  color: var(--background);

  h1 {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
  }
  .section {
    max-width: 800px;
    margin: 10px auto;
    background-color: var(--default);
    border-radius: 8px;
    height: 60px;
    color: var(--background);
    font-size: 14px;
    position: relative;

    @media screen and (max-width: 768px) {
      height: fit-content;
    }

    img {
      position: absolute;
      right: 15px;
      top: 22.5px;
      transition: 0.25s ease-in-out;
      filter: invert(1);
    }

    padding: 15px 25px 22px;
    cursor: pointer;

    &.active {
      height: fit-content;

      img {
        transform: rotate(180deg);
      }
      div {
        margin-top: 10px;
      }
    }

    h5 {
      font-size: 20px;
    }
  }
}

#App {
  overflow: hidden;
  overflow-y: auto;
  width: 100vw;
}

#App.fixed {
  overflow: hidden;
}

.hide-desktop {
  display: none !important;
}

.hide-mobile {
  display: block;
}

.hide {
  display: none;
}

video {
  object-fit: cover;
}

input {
  padding: 0 16px;
  width: 100%;
  height: 64px;
  border: none;
  outline: none;
  color: var(--background);
  border-radius: 8px;

  &:focus,
  &:hover,
  &:active {
    border: none;
    outline: none;
    box-shadow: none;
  }
  &::placeholder {
    color: #888;
  }
}

.hide {
  display: none !important;
}

.breadcrumbs {
  text-transform: capitalize;
  font-size: 14px;
  max-width: 1129px;
  margin: auto;

  span {
    opacity: 0.5;
    margin: 0 5px;
  }

  a {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 768px) {
  .hero,
  .modals {
    h1 {
      font-size: 31px;
      line-height: 1.25;
    }
  }
  .hide-desktop {
    display: block !important;
  }
  .hide-mobile {
    display: none !important;
  }

  nav {
    .openedMenu {
      z-index: 99991;
    }
  }

  .app-footer {
    padding: 30px 15px 60px;

    .container {
      padding: 0 !important;
      flex-wrap: wrap;
      justify-content: flex-start !important;

      .column {
        width: 50%;

        h6 {
          margin-bottom: 10px;
        }

        .items {
          .item {
            margin: 15px 0;
          }
        }

        &:last-child {
          width: 100%;

          .items {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }

  .hArrows {
    display: none;
  }
}

.fade-in {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.is-native {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;

  .app-content {
    height: calc(100vh - 60px);
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    .landing-home .hero-slider video,
    .hero-simple video,
    .dots {
      top: 0;
    }

    .dots {
      margin-top: 10px;
    }
    .hero-simple {
      height: 25vh;
      padding-bottom: 5vh;
    }
  }

  .tabbed-nav {
    height: 60px;
  }
}

.st-inline-share-buttons {
  z-index: 1 !important;
}
