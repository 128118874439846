.title-with-tabs {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  h4 {
    font-size: 18px;
    margin: 0 20px 0 0 !important;
    font-weight: 800;
  }
}

.hArrows {
  display: flex;
  position: absolute;
  right: 10px;

  img {
    padding: 0 10px;
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

.story {
  text-align: center;
  padding: 140px 0 0;

  h1 {
    max-width: 600px;
    margin: auto;
  }

  h3 {
    font-size: 40px;
    font-weight: 800;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  }

  h5 {
    margin: 40px auto 10px;
  }

  img {
    margin: 0 auto 60px;
  }

  .panel-container {
    .panel {
      height: 615px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-align: left;
      padding: 5%;
      overflow: hidden;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      b {
        color: var(--primary);
      }

      .overlay-left {
        background: linear-gradient(
          90deg,
          black 0%,
          rgba(0, 0, 0, 0.4) 20%,
          rgba(0, 0, 0, 0) 100%
        );
      }

      h3 {
        opacity: 0.25;
        position: relative;
        font-size: 70px;
      }
      .paragraph {
        max-width: 600px;
        position: relative;
        opacity: 0;
        font-size: 1px;
      }

      h3,
      .paragraph {
        transition: 0.25s ease-in-out;
      }

      &:hover {
        h3 {
          font-size: 46px;
          opacity: 1;
        }
        .paragraph {
          font-size: 22px;
          opacity: 1;
        }
      }
    }
  }
}

.simple-hero {
  b {
    color: var(--primary);
  }
}

.latest-publications {
  background-color: var(--opposite);
  padding: 100px 0;
  color: var(--background);

  .publication-list {
    max-width: 1200px;
    padding: 0 20px;
    margin: auto;

    h4 {
      font-size: 28px;
      font-weight: 700;
    }

    .collection {
      display: flex;
      margin: -10px;

      .card {
        height: 395px;
        width: 590px;
        background-color: var(--default);
        border-radius: 8px;
        margin: 10px;
        background-size: cover;
        background-position: left center;
        display: flex;
        align-items: flex-end;
        position: relative;

        .label {
          position: absolute;
          top: 10px;
          right: 15px;
          font-size: 14px;
          text-transform: capitalize;
        }

        &:hover {
          .body {
            background-color: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(16px);
            color: var(--opposite);
            height: 260px;

            .faded {
              opacity: 1;
              max-height: 130px;
              overflow: hidden;
            }

            .footer {
              color: var(--opposite);
              background-color: transparent;
            }
          }
          .button {
            margin-top: 10px;
            display: block;
            height: initial;
            opacity: 1;
            position: absolute;
            bottom: 15px;
            left: 15px;
            right: 15px;
            width: calc(100% - 30px);
          }
        }

        .button {
          height: 0px;
          opacity: 0;
          display: none;
          transition: 0.25s ease-in-out;
          text-align: center;
        }

        .body {
          height: 130px;
          border-radius: 8px;
          padding: 15px 20px;
          font-size: 14px;
          line-height: 1.3;
          overflow: hidden;
          background-color: var(--default);
          color: var(--background);
          transition: 0.25s ease-in-out;
          position: relative;

          h5 {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 2.5px;
            font-weight: 800;
          }

          .footer {
            font-size: 12px;
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 5px 20px 0;
            width: 100%;
            height: 33px;
            background-color: var(--default);
            color: var(--secondary-hover);

            span {
              margin: 0 2.5px;
              color: var(--primary);
            }
          }
        }
      }
    }
  }

  h4 {
    font-size: 24px;
    font-weight: 800;
  }
}

.landing-page {
  position: relative;
  color: var(--default);
  padding-bottom: 100px;

  .hero {
    overflow-x: auto;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;

    .email-hero-form {
      .call-to-action {
        .button {
          width: 200px;
          margin-left: 10px;
        }
      }
    }

    .landingBgInfo {
      h1 {
        font-size: 54px;
        line-height: 1.1;
      }
    }

    &.featured-on {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 460px;
      margin-top: 60px;
      padding-bottom: 20px;

      h1 {
        margin-bottom: 60px;
      }

      .features {
        display: flex;
        a {
          width: calc(100% / 6);
          padding: 2.5%;
        }
      }
      @media screen and (max-width: 768px) {
        height: 100%;
        padding-bottom: 40px;
        margin-top: -60px;

        .features {
          flex-direction: column;

          a {
            width: 100%;
          }

          img {
            width: 200px;
            padding: 20px;
          }
        }
      }
    }

    .audio-control {
      cursor: pointer;
      position: absolute;
      right: 2vw;
      bottom: 2vw;
      z-index: 1;
    }

    .line {
      display: flex;
      margin-bottom: 15px;

      img {
        margin-right: 7.5px;
      }
    }

    .call-to-action {
      margin-top: 40px;
    }

    &.interactive {
      .landingBgInfo {
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        max-width: none;
        width: 100%;
      }

      span {
        color: var(--primary);
      }
      h1 {
        font-size: 72px;
        width: 380px;
        text-align: right;
      }
      .button {
        margin-top: 64px;
        height: 52.34px;

        img {
          margin-right: 7.5px;
        }
      }
      h4 {
        font-weight: 800;
        font-size: 37px;
        text-transform: uppercase;
        margin-bottom: 10px;

        span {
          background-color: var(--primary);
          color: black;
          padding: 0 15px;
          font-size: 42px;
        }
      }
      .overlay {
        background: linear-gradient(
          -90deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.5) 50%,
          rgba(0, 0, 0, 0) 100%
        );
        z-index: 0;
      }
      .not-toggled-video {
        .overlay {
          display: none;
        }
        video {
          object-fit: center;
        }
      }
    }
  }

  .new-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto 80px;
    padding: 0 20px;
    max-width: 1400px;
    position: relative;

    .badge {
      background-color: var(--primary);
      border-radius: 33px;
      height: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
      font-size: 14px;
      color: black;
      font-weight: 400;
      margin-left: 10px;
    }

    .left {
      display: flex;
      margin-right: 100px;
      position: relative;

      &.one {
        .image {
          margin: -160px 0 100px;
        }
      }

      .image {
        background-size: contain;
        background-position: bottom center;
        background-repeat: no-repeat;
        height: 600px;
        width: 360px;
        margin: -200px -65px 100px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 60px;

        .overlay {
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.1) 30%,
            rgba(0, 0, 0, 0) 100%
          );
          z-index: 0;
        }

        .meta {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            margin-right: 5px;
            margin-top: 1px;
          }

          div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 5px;
          }
        }

        .content {
          position: relative;
          z-index: 2;
          text-align: center;

          h5 {
            font-size: 18px;
            font-weight: 800;
          }
        }

        &.main {
          z-index: 1;
          padding-bottom: 0;
          height: 600px;
          width: 410px;

          h5 {
            font-size: 24px;
            font-weight: 800;
          }
        }
      }
    }

    .right-content {
      display: flex;
      margin-top: 20px;

      .block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 112px;
        width: 128px;
        background-color: #333;
        border-radius: 6px;
        font-size: 12px;
        margin-right: 15px;

        img {
          height: 25px;
          width: 25px;
          object-fit: cover;
        }
      }

      h5 {
        font-weight: 500;
        font-size: 24px;
      }
    }

    .right {
      max-width: 420px;
      z-index: 1;

      h3 {
        font-weight: 800;
        font-size: 40px;
        display: flex;
        align-items: center;
      }
    }
  }

  .simple-hero {
    padding: 20px;
    margin: 200px auto 100px;
    text-align: center;
    max-width: 800px;
    position: relative;
    z-index: 1;

    p {
      font-size: 24px;
      opacity: 0.85;
      font-weight: 400;
    }

    h1 {
      margin-bottom: 35px;
    }

    h5 {
      color: var(--primary);
      font-size: 24px;
      font-weight: 500;
      margin: 30px auto 40px;
    }

    .button {
      max-width: 268px;
      margin: 20px auto;
      height: 52.34px;
    }
  }

  h1 {
    font-size: 72px;
    font-weight: 800;
    line-height: 1;
  }

  p {
    font-weight: 500;
    font-size: 20px;
  }

  .show-mobile {
    display: none;
  }

  video {
    object-fit: cover;
    object-position: top center;
    width: 100vw;
    height: calc(100vh - 60px);
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 0;
  }

  .toggled-video {
    video {
      height: 100% !important;
    }
  }

  .additionalInfo {
    margin: 15px 0 0;
    color: #9e9e9e;
    font-size: 14px;
    font-weight: 400;
    width: 50%;

    img {
      margin-right: 7.5px;
      cursor: pointer;
      height: 25px;
      width: 25px;
      object-fit: cover;
    }

    a {
      margin: 0 5px;
      text-decoration: underline;
      &:hover {
        color: #fff;
      }
    }
  }

  .collab-container {
    display: flex;
    max-width: 600px;
    margin: 0 auto;

    .collab {
      font-size: 3em;
      color: white;
      z-index: 1;
      margin: 0 20px;
    }

    img {
      margin-bottom: 20px;
      width: 80px;
      height: 80px;
      height: auto;
      display: block;
      object-fit: contain;
      position: relative;
    }
  }

  .landingBgInfo {
    position: relative;
    color: #fff;
    max-width: 700px;
    position: absolute;
    left: 5vw;
    bottom: 0;
    top: 22.5vh;
    margin: auto;

    h3 {
      color: var(--primary);
      font-weight: 800;
      font-size: 32px;
      margin-bottom: 15px;
    }

    .call-to-action {
      display: flex;

      input {
        width: 50%;
      }
      input,
      .button {
        height: 52px !important;
      }
      .button {
        width: 320px;
      }
    }

    p {
      margin: 20px 0 30px;
      font-weight: 400;
    }
    b {
      color: var(--primary);
    }
  }

  .parrallax-section {
    margin: 100px 0;
    color: var(--default);

    h1 {
      font-size: 74px;
      font-weight: 800;
      line-height: 1;
      max-width: 640px;
      margin-bottom: 40px;
    }
    .hint {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      width: fit-content;

      p {
        margin-bottom: 20px;
      }
    }
    .content {
      height: 80vh;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .body-content {
        width: 500px;
      }

      .video-content {
        height: 615px;
        width: 467px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      &.fixed {
        position: fixed;
        background-color: black;
        height: 80vh;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        z-index: 3;
        overflow: auto;
      }
    }
  }

  .text-hero {
    margin: 100px auto 120px;
    color: var(--default);
    text-align: center;

    .call-to-action {
      display: flex;
      max-width: 400px;
      margin: 60px auto 20px;

      input,
      .button {
        height: 52px !important;
      }
      .button {
        width: 134px;
        margin-left: 15px;
      }
    }

    h1 {
      max-width: 724px;
      margin: auto;
      font-size: 56px;
    }

    .container {
      display: flex;
      justify-content: space-between;
      margin: auto;
      font-size: 14px;
      margin-top: 40px;

      p {
        font-weight: 800;
        letter-spacing: 3px;
        font-size: 14px;
        text-transform: uppercase;
      }

      hr {
        width: 53px;
        border-top: 3px solid #fff;
        margin: 20px auto;
      }

      h6 {
        font-size: 45px;
        font-weight: 800;

        span {
          margin-left: 5px;
          opacity: 0.5;
        }
      }

      div {
        width: calc(100% / 3);
        padding: 20px;
      }
    }
  }

  h4 {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .close-interactive-video {
    position: absolute;
    top: -30px;
    right: 40px;
  }

  .card-stack-section,
  .content-preview-section {
    margin: 120px 0 120px 0;
    color: var(--default);
    z-index: 1;
    position: relative;

    .button {
      max-width: 268px;
      margin: 20px auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .title-with-tabs,
    .collection-container:first-child {
      margin-left: 7.5vw;
    }
  }
}

.courses-section {
  margin: 120px 0 120px 0;
  color: var(--default);
  z-index: 1;
  position: relative;

  .button {
    max-width: 268px;
    margin: 20px auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .title-with-tabs,
  .collection-container:first-child {
    margin-left: 7.5vw;
  }

  .more-button {
    max-width: 268px;
    margin: 30px auto;
    height: 52.34px;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
  }

  @media screen and (max-width: 768px) {
    .title-with-tabs,
    .collection-container:first-child {
      margin-left: 0px;
    }
  }
}

.faq-landing {
  background-color: var(--opposite);
  padding: 80px 0 120px;

  h1 {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: var(--background);
    margin-bottom: 40px;
  }

  .faq {
    max-width: 800px;
    margin: auto;
    padding: 20px 0;
  }
  h3 {
    font-size: 28px;
    font-weight: 700;
  }
}

@media (max-width: 768px) {
  .faq-landing {
    padding: 20px 0 120px;
  }
  .courses-section {
    margin: 40px 0;
    color: var(--default);
    z-index: 1;
    position: relative;

    .title-with-tabs {
      overflow: auto;

      h4 {
        margin-left: 7.5vw !important;
        white-space: nowrap;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .collection-container {
      &:first-child {
        margin-left: 7.5vw;
      }
    }

    .more-button {
      max-width: 268px;
      margin: 20px auto;
      position: relative !important;
      left: 0 !important;
      right: 0 !important;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .landing-page {
    padding-bottom: 10px;
    .simple-hero {
      margin: 100px 0 60px;
    }

    .hero {
      align-items: flex-end;
      .landingBg {
        .landingBgInfo {
          h1 {
            font-size: 26px;
          }
          h3 {
            font-size: 16px;
            margin-bottom: 10px;
          }
          .line {
            margin-bottom: 7.5px;
            display: none;
          }

          p {
            margin: 10px 0 20px;
            font-size: 0.9em;
          }
          .call-to-action,
          .additionalInfo {
            margin: 0;

            input {
              margin: 5px 0;
            }
          }
        }
      }
    }
  }

  .faq-landing {
    background-color: var(--opposite);
    padding: 20px 0 40px;

    .faq {
      padding: 20px !important;
    }

    h3 {
      font-size: 18px;
      font-weight: 700;
    }
    h1 {
      font-size: 24px;
    }
  }

  .login-page {
    background-position: top center !important;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;

    video {
      display: none;
    }

    .loginInfoWrapper .loginInfo {
      padding: 0px;
      background: none;
    }

    .checkboxWrapper {
      p {
        text-align: left;
      }
    }

    .landingBg {
      .overlay {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.6) 85%,
          rgba(0, 0, 0, 0.25) 100%
        );
      }
    }

    .left {
      width: 100vw;
      padding: 0 15px;

      .collab-container {
        justify-content: center;
      }
    }

    h3 {
      font-size: 26px;
    }

    p {
      font-size: 14px;
    }

    .collab {
      font-size: 2.5em;
      color: white;
      margin: 0 15px;
      opacity: 0.5;
    }
    .logo {
      height: 60px;
      width: 60px;
      object-fit: contain;
    }
  }

  .landing-page {
    .faq-landing {
      padding: 60px 0 40px;
    }
    .simple-hero,
    .email-hero {
      h1 {
        font-size: 38px;
      }
      p,
      h5 {
        font-size: 18px;
        font-weight: 400;
      }
      h5 {
        font-weight: 700;
      }
      .additionalInfo {
        width: 100%;
        display: flex;
      }
    }
    .story {
      padding: 60px 0 0;
      .header {
        padding: 0 20px;
        h1 {
          font-size: 38px;
        }
      }
    }
    .hero {
      min-height: 550px;

      .audio-control {
        top: 18vw;
        bottom: unset;
      }

      video,
      .overlay {
        min-height: 450px;
      }
      input {
        max-width: 200px;
      }

      .button {
        max-width: 120px;
      }

      .additionalInfo {
        width: 100%;

        a {
          margin-right: 5px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &.interactive {
        min-height: 550px;

        .top {
          min-height: 100px;
        }

        video,
        .overlay {
          min-height: 450px;
        }
        .landingBgInfo {
          flex-direction: column;
          flex-flow: column-reverse;
          padding: 20px;

          h4,
          h1 {
            font-size: 26px;
          }

          .bigger {
            font-size: 30px;
          }

          h4 span {
            font-size: 29px;
          }

          div,
          h1 {
            width: 100%;
            text-align: center;

            .button.outlined {
              max-width: 100%;
              margin: 20px auto 0px;
              width: 90%;
            }
          }
        }
      }
    }

    .text-hero {
      padding: 100px 20px;

      h1 {
        font-size: 31px;
      }

      .container {
        flex-direction: column;

        div {
          width: 100%;
        }
      }
    }

    .new-content {
      flex-direction: column;
      flex-flow: column-reverse;

      .right {
        margin-top: 0;
        width: 100%;
        text-align: center;

        .right-content {
          .block {
            &:last-child {
              margin-right: 0;
            }
          }
        }

        h3 {
          display: block;
        }

        .badge {
          width: 140px;
          margin: 5px auto 15px;
        }
      }

      .left {
        margin-right: 0;
        flex-direction: column;

        &.one {
          .image {
            &.main {
              width: 360px;
              margin-top: -200px;
            }
          }
        }

        .right-content {
          display: flex !important;
          justify-content: space-around;
          .block {
            width: 105px;
            h5 {
              font-size: 21px;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }

        .image {
          height: 400px;
          .content {
            h5 {
              font-size: 21px;
              margin-bottom: 5px;
            }
            .meta {
              font-size: 12px;
            }
          }
        }

        .image {
          width: 280px;

          &.main {
            width: 360px;
            margin-top: -140px;
            margin-bottom: 20px;
          }
        }
      }
    }

    .latest-publications {
      padding: 60px 0;
      .publication-list .collection {
        display: block;
        margin: 0;

        .card {
          width: 100%;
          margin: 20px auto;

          .body h5 {
            font-size: 20px;
            line-height: 1.1;
          }
        }
      }
    }

    .faq {
      .section {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        h5 {
          font-size: 16px;
          max-width: 99%;
        }
      }
    }
  }
}

.email-hero {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1400px;
  margin: 60px auto 140px;

  h1 {
    margin-bottom: 20px;
    font-size: 56px;
  }

  p {
    opacity: 0.85;
  }

  .additionalInfo {
    width: 100%;
    display: flex;
  }

  .left,
  .email-hero-form {
    width: 50%;

    input,
    .button {
      height: 50px;
      margin-bottom: 20px;
    }
  }
  .email-hero-form {
    max-width: 400px;
  }
}

@media screen and (max-width: 768px) {
  .email-hero {
    flex-direction: column;
    padding: 20px;
    margin: 60px auto;
    text-align: center;

    p {
      margin-bottom: 30px;
    }

    .additionalInfo {
      width: 50%;
      display: block;
    }

    .left,
    .right {
      width: 100%;
    }
  }

  .email-hero .left,
  .email-hero .email-hero-form {
    width: 100%;
  }

  .landing-page {
    p {
      font-size: 18px;
    }
    .text-hero {
      margin: -20px auto 0;

      .call-to-action {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        a {
          margin: 10px auto !important;
          padding: 0;
        }
      }
    }
    .hero {
      height: 100 vh;

      &.interactive {
        height: 100vh;
      }
      .landingBg {
        .landingBgInfo {
          position: relative;
          left: unset;
          right: unset;
          bottom: unset;
          top: unset;
          padding: 0 20px;
          margin-top: 25vh;
        }
        .overlay {
          background: linear-gradient(
            0deg,
            black 0%,
            rgba(0, 0, 0, 0.8) 10%,
            rgba(0, 0, 0, 0) 100%
          );
        }
        video {
          height: 55vh;
          min-height: 55vh;
        }

        .call-to-action {
          flex-direction: column;

          input,
          .button {
            width: 100%;
            max-width: 100%;
            margin: 10px 0;
          }
        }
      }
    }
  }

  .story {
    .panel-container {
      .panel {
        height: 75vh;

        h3 {
          font-size: 32px !important;
          opacity: 1;
          line-height: 1.1;
          margin-bottom: 5px;
        }

        .paragraph {
          height: unset;
          font-size: 14px;
          opacity: 1;
        }
      }
    }
  }
}
