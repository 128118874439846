.contact-modal {
  h3 {
    font-size: 31px;
    font-weight: 700;
    margin-bottom: 5px;

    @media screen and (max-width: 768px) {
      font-size: 26px;
    }
  }
  .close-icon {
    position: fixed !important;
    top: 15px;
    right: 15px;
    cursor: pointer;
    opacity: 0.75;
    height: 30px !important;
    width: 30px !important;
  }
  .content {
    .contact-form {
      background: none;
      backdrop-filter: none;
      padding: 0;

      .single,
      .double {
        padding: 5px 0;
      }
    }
  }
  a {
    font-weight: 800;
  }

  p {
    margin-bottom: 10px;
  }

  .cancel-content {
    .actions {
      display: flex;

      .button {
        height: 41px;
        width: 45%;
        margin-right: 10px;
      }
    }
  }
}
