.collection-landing {
  position: relative;
  color: var(--default);

  h4 {
    font-size: 28px;
  }

  .courses-section {
    color: var(--default);
    z-index: 1;
    position: relative;

    .button {
      max-width: 268px;
      margin: 20px auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .title-with-tabs {
      margin-left: 7.5vw;
    }

    .collection-container:first-child {
      .card {
        margin-left: 7.5vw;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .collections-section {
      padding: 20px 5vw 60px;

      .card-collection {
        display: flex;
        flex-direction: column;
      }
    }

    h1 {
      margin-bottom: 0;
    }
  }
}

.collections-section {
  padding: 60px 5vw;
  h4 {
    margin: 0 0 25px 7.5vw;

    font-weight: 800;
  }

  .button {
    max-width: 268px;
    margin: 40px auto 20px;
  }

  .card-collection {
    position: relative;
    z-index: 2;
    overflow-x: auto;
    margin: 0 -0.5vw;
    display: grid;
    //grid-template-columns: repeat(4, 1fr);
    grid-template-columns: repeat(auto-fill, minmax(420px, 2fr));
    grid-auto-rows: auto;
    grid-gap: 1rem;

    .card {
      height: 299px;
      width: 100%;
      border-radius: 8px;
      position: relative; //allows for horizontal scroll
      margin-right: 20px;
      overflow: hidden;
      background-repeat: no-repeat;

      .overlay {
        background: linear-gradient(
          120deg,
          rgba(0, 0, 0, 0.9) 0%,
          rgba(0, 0, 0, 0.6) 40%,
          rgba(0, 0, 0, 0) 60%
        );
      }

      .content {
        position: relative;
        padding: 20px;

        h5 {
          font-size: 24px;
          font-weight: 800;
        }
      }
    }
  }
}
