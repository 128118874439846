.portal {
  position: relative;

  .user-table {
    margin: -40px auto 60px;
    width: 100%;
    max-width: 800px;
    text-align: center;
    h6 {
      margin: auto;
      font-size: 11px;
    }
    table {
      margin: 20px auto;
      text-align: left;
      min-width: 340px;
      td {
        border: 1px solid #555;
        padding: 2.5px 5px;
        font-size: 13px;
      }
    }
  }

  .overlay {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.7) 85%,
      rgba(0, 0, 0, 0.25) 100%
    );
    z-index: 1;
  }

  video {
    position: fixed;
  }

  .portal-container,
  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .portal-container {
    z-index: 1;
    color: white;
    overflow: auto;

    header {
      height: 30vh;
      margin-top: 64px;
      min-height: 260px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        font-size: 68px;
        font-weight: 700;
        line-height: 1.3;
      }

      hr {
        margin: 10px auto 20px;
        border-top: 3px solid var(--primary);
        width: 100px;
      }
    }

    .button-group,
    .submit-button {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .submit-button {
      margin: 0px 0 40px;

      .button {
        cursor: not-allowed;

        &.active {
          cursor: pointer;
          background-color: var(--primary);
        }
      }
    }

    .meta {
      font-size: 14px;
      font-weight: 600;
      padding: 10px;
      max-width: 500px;
      margin: 40px auto 10px;
      position: relative;
      border: 1px solid var(--success);
      color: var(--success);

      &.error {
        border-color: var(--danger);
        color: var(--danger);

        .errorList {
          font-weight: 400;
          color: white;
          opacity: 0.75;
          font-size: 13px;
        }
      }

      img {
        position: absolute;
        right: 10px;
        top: 12px;
        opacity: 0.5;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }

    .button {
      width: 240px;
      cursor: pointer;
      background-color: #4a4c4e;
      height: 52px;
      color: rgba(255, 255, 255, 0.85);
      padding: 0 15px;
      margin: 5px 10px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 500;

      &:hover {
        background-color: #5b5e60;
      }

      img {
        margin-right: 5px;
      }

      &.active {
        background-color: var(--primary);
        color: black;
      }
    }

    .content {
      padding: 40px 15px;
      text-align: center;

      h3 {
        font-size: 38px;
        font-weight: 700;
        line-height: 1.3;
        margin-bottom: 20px;
      }

      h4 {
        font-size: 28px;
        font-weight: 700;
        line-height: 1.3;
        color: var(--primary);
      }

      .instructions {
        display: flex;
        justify-content: center;
        padding: 20px 0;

        .item {
          display: flex;
          justify-content: center;
          text-align: left;
          width: 25%;
          padding: 15px;
          max-width: 320px;
        }

        img {
          height: 60px;
          width: 60px;
          padding: 7.5px;
          margin-top: 22.5px;
        }
        h5 {
          font-size: 18px;
          font-weight: 700;
          margin-top: 5px;
        }

        div {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .portal {
    .portal-container {
      header {
        height: 200px;
        min-height: 200px;
        h1 {
          font-size: 32px;
        }
      }

      .button-group {
        flex-direction: column;
      }

      .content {
        padding: 0 5px;
        h3 {
          font-size: 28px;
        }
        h4 {
          font-size: 18px;
        }

        .instructions {
          flex-direction: column;
          margin-bottom: 40px;

          div {
            text-align: center;
          }

          img {
            margin-top: 10px;
          }

          h5 {
            font-size: 16px;
          }

          .item {
            width: 100%;
            padding: 5px;
            max-width: 100%;
            align-items: center;
            flex-direction: column;
          }
        }
      }
    }
  }
}
