.session-content {
  max-width: 1311px;
  grid-gap: 1em;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(2, 1fr);
  //grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  overflow-x: auto;
  position: relative;
  z-index: 2;
  margin: auto;
  padding: 0px 20px 60px;

  .card {
    overflow: hidden;
    position: relative;
    padding: 20px 25px 25px;
    border-radius: 12px;
    height: 296px;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;

    &.browse-courses {
      display: flex;
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 32px;
        font-weight: 800;
        line-height: 1.1;
      }
    }

    video {
      object-fit: cover;
      height: 100%;
      width: 100%;
      top: 0;
      object-position: center;
    }

    .overlay {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .watched-indicator {
        height: 5px;
        background-color: var(--primary);
      }
    }

    .avatar {
      margin-right: 10px;
    }

    .play-button {
      position: absolute;
      right: 20px;
      bottom: 20px;
    }

    h3 {
      font-size: 24px;
      font-weight: 800;
      margin: 10px 0 0;
      position: relative;
    }

    &:hover {
      .content {
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 250px;
      }
      .play-button,
      h6,
      .body {
        opacity: 0;
      }
      .overlay {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.6) 80%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }

    @media screen and (max-width: 768px) {
      &:hover {
        .content {
          display: none;
        }
        .play-button,
        h6,
        .body {
          opacity: 1;
        }
        .overlay {
          background: initial;
        }
      }
    }

    .content {
      position: relative;
      opacity: 0;
      transition: height 0.25s ease-in-out;
      height: 600px;

      .button {
        margin: 20px 0;
        max-width: 204px;
        height: 46px;

        img {
          filter: invert(1);
          margin-right: 10px;
          height: 18px;
        }
      }

      p {
        font-size: 14px;
        margin: 5px 0;

        @media screen and (max-width: 768px) {
          max-height: 150px;
          overflow: hidden;
        }
      }
    }

    .header {
      display: flex;
      font-size: 16px;
      position: relative;

      .avatar {
        height: 38px;
        width: 38px;
        border-radius: 50%;
        object-fit: cover;
      }

      .meta {
        font-size: 12px;
      }
    }
  }

  .body {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 60%;
    display: flex;
    flex-direction: column;

    h3 {
      line-height: 1.25;

      .badge {
        font-size: 12px;
        background-color: var(--primary);
        padding: 5px 10px;
        border-radius: 15px;
        color: var(--background);
        font-weight: 400;
        vertical-align: middle;
      }
    }

    h6 {
      margin-top: 5px;
    }
  }
}

.heroBgInfo {
  position: relative;
  color: var(--default);
  max-width: 680px;
  position: absolute;
  top: 0;
  bottom: 20px;
  left: 7.5vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.25s ease-in-out;

  h1 {
    white-space: nowrap;

    span {
      display: none;
    }
  }

  &.flushed {
    left: 40px;
    bottom: 20px;
    justify-content: flex-end;

    h1 {
      font-size: 41px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 768px) {
      bottom: 0;
      top: 0;
      left: 20px;
      justify-content: flex-end;
      align-items: flex-start;
      overflow: hidden;

      h1 {
        font-size: 21px;
        margin-bottom: 10px;
        text-align: left;
      }
    }

    .block {
      display: none;
      opacity: 0;
      height: 0 !important;
      transition: 0.25s ease-in-out;
    }

    .session-summary {
      height: 0 !important;
    }

    p {
      margin: 0;
      font-size: 0;
    }
  }

  .call-to-action {
    display: flex;
    max-width: 400px;

    input,
    .button {
      height: 52px !important;
    }
    .button {
      width: 134px;
      margin-left: 15px;
    }
  }

  p {
    margin: 20px 0 30px;
    font-weight: 400;
    font-size: 16px;
    transition: 0.25s ease-in-out;
  }

  b {
    text-decoration: underline;
  }
}

.interactive-section {
  margin: 80px 0 40px;

  .hArrows {
    img {
      filter: invert(1);
    }
  }

  .interactive-content {
    &::-webkit-scrollbar {
      display: none;
    }
    padding: 10px 0 40px;
    max-width: unset;

    a {
      &:first-child {
        margin-left: 40px;
      }
      &:last-child {
        margin-right: 40px;
      }
    }
  }
  h2 {
    color: var(--background);
    font-size: 28px;
    font-weight: 700;
    margin-left: 40px;
  }
}

.course-summary-container {
  max-width: 1331px;
  padding: 50px 20px 80px;
  margin: auto;

  h3 {
    margin-bottom: 20px;
  }
}

.block {
  width: 186px;
  background: #242424;
  border-radius: 8px;
  height: 214px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  position: relative;
  overflow: hidden;

  &.talk-block {
    width: 489px;
    font-size: 20px;
    font-weight: 700;

    .talk-block-content {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      padding: 25px;
      z-index: 2;
    }

    .overlay-left {
      z-index: 1;
      width: 100%;
      /*  background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.8) 30%,
        rgba(0, 0, 0, 0.5) 50%,
        rgba(0, 0, 0, 0.1) 65%,
        rgba(0, 0, 0, 0) 100%
      ); */
      background: linear-gradient(
        89.6deg,
        #2d2a33 13.29%,
        rgba(45, 42, 51, 1) 38.91%,
        rgba(45, 42, 51, 0.5) 48%,
        rgba(45, 42, 51, 0.1) 56%,
        rgba(45, 42, 51, 0) 99.63%
      );
    }

    .meta {
      font-size: 16px;
      font-weight: 400;
      margin: 0px 0 25px;
    }

    .resting-image {
      height: 500px;
      position: absolute;
      right: -2px;
      top: -25px;
    }

    .button {
      width: 124px;
      img {
        filter: invert(1);
        height: 17px;
      }
    }
  }

  img {
    height: 43px;
    width: auto;
  }

  h5 {
    font-size: 36px;
    font-weight: 500;
    margin: 5px auto;
  }
}

section {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    display: grid;
    //grid-template-columns: repeat(4, 1fr);
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 0.5rem;
    .block {
      width: 100%;
      height: 124px;
      font-size: 14px;

      img {
        height: 30px;
      }

      h5 {
        font-size: 25px;
        margin: 5px auto;
      }

      &.talk-block {
        width: calc(100vw - 40px);
        height: 200px;
      }
    }
  }
}

.course-landing {
  position: relative;
  color: var(--default);
  min-height: 100vh;

  .block {
    display: flex;
  }

  .courses-section {
    padding: 0px 0 80px;
    margin: 120px 0 20px 0;

    @media screen and (max-width: 768px) {
      padding: 20px 0;
    }
  }

  .sessions-title {
    font-size: 36px;
    color: var(--background);
    font-size: 54px;
    font-weight: 800;
    text-align: center;
    margin: 60px auto 50px;
    line-height: 1.5;

    @media screen and (max-width: 767px) {
      font-size: 31px;
      margin: 40px auto;
    }
  }

  h3 {
    font-size: 36px;
    font-weight: 500;
  }

  .overlay {
    &.hide {
      background: transparent;
    }
  }

  .secondary-filters {
    display: flex;
    margin-top: 30px;
    transition: 0.25s ease-in-out;
    opacity: 1;

    &.hide {
      opacity: 0;
      margin: 0;
    }

    img {
      margin-right: 7.5px;
    }

    div {
      margin-right: 15px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
      border: 1px solid var(--secondary-hover);
      //background-color: var(--background);
      border-radius: 22px;
      font-size: 12px;
      transition: 0.25s ease-in-out;
    }
  }

  .hero {
    overflow-x: auto;
    height: 100vh;
    min-height: 600px;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;

    .overlay.small {
      background: linear-gradient(
        -15deg,
        black 0%,
        rgba(0, 0, 0, 0.2) 30%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    .line {
      display: flex;
      margin-bottom: 15px;

      img {
        margin-right: 7.5px;
      }
    }

    .call-to-action {
      margin-top: 40px;
    }

    .actions {
      width: 100%;
      opacity: 1;
      display: flex;
      justify-content: flex-start;

      .primary,
      .secondary {
        height: 52px;
        width: 232px;
        margin-right: 15px;
        display: flex;
        margin-bottom: 20px;

        img {
          display: block;
          margin-right: 5px;
        }
      }
    }
  }

  h1 {
    font-size: 72px;
    font-weight: 800;
    line-height: 1;
  }

  p {
    font-weight: 500;
    font-size: 20px;
  }

  video {
    object-fit: cover;
    object-position: top center;
    width: 100vw;
    height: calc(100vh - 60px);
    min-height: calc(600px - 60px);
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 0;
  }

  .video-controls {
    position: relative;
    position: absolute;
    bottom: 25px;
    right: 25px;
    display: flex;

    img {
      cursor: pointer;
      margin: 5px;
    }

    span {
      display: flex;
    }
  }

  .opposite-body {
    background-color: var(--opposite-body);
    padding: 10px 0 60px;
  }

  .summary-banner-container {
    padding: 0 20px;
  }

  .summary-banner {
    height: 117px;
    max-width: 1311px;
    margin: 40px auto 10px;
    background: linear-gradient(125.25deg, #535353 3.46%, #000000 100%);
    border-radius: 8px;
    color: var(--default);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 30px;

    .avatar {
      height: 78px;
      width: 78px;
      border-radius: 50%;
      object-fit: cover;
    }

    section {
      width: calc(100% - 240px);
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        padding: 0 40px;
      }
    }

    div {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 800;
      border-right: 1px solid var(--default);
      justify-content: center;

      img {
        margin-right: 5px;
      }

      &.last-child {
        border: none;
      }
    }

    .button {
      height: 52px;
      width: 176px;
      img {
        filter: invert(1);
      }
    }
  }

  .text-banner {
    color: var(--background);
    text-align: center;
    max-width: 954px;
    margin: 80px auto 120px;

    h1 {
      font-size: 54px;
      font-weight: 800;
      margin-bottom: 20px;
    }
    p {
      font-size: 20px;
    }

    @media screen and (max-width: 768px) {
      margin: 40px auto;

      p {
        font-size: 16px;
      }
    }
  }

  .conversations-banner-container {
    padding: 10px 20px 20px;
  }

  .conversations-banner {
    height: 685px;
    max-width: 1311px;
    margin: 40px auto;
    background-color: var(--background);
    display: flex;
    border-radius: 18px;
    position: relative;

    .overlay {
      border-radius: 18px;
      background: linear-gradient(
        89.6deg,
        #2d2a33 13.29%,
        rgba(45, 42, 51, 0.804749) 38.91%,
        rgba(45, 42, 51, 0.104749) 42.91%,
        rgba(45, 42, 51, 0) 99.63%
      );
      z-index: 1;
    }

    h3 {
      font-size: 42px;
      font-weight: 700;
      line-height: 1.25;
    }

    .left {
      text-align: center;
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      margin: auto;

      img {
        height: 35px;
        margin-bottom: 20px;
      }

      .button {
        width: 240px;
        margin: 30px auto 0;
        height: 52px;
      }

      .meta {
        display: flex;

        div {
          width: 160px;
          padding: 20px;
          p {
            font-size: 16px;
            line-height: 1.25;
          }
          h4 {
            font-size: 56px;
            font-weight: 800;
          }
        }
      }
    }

    .right {
      width: 60%;
      display: flex;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      overflow: hidden;
      border-radius: 0 18px 18px 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center bottom;
      height: 100%;
      border-radius: 0 18px 18px 0;
      clip-path: inset(0 0 round 18px);

      video {
        height: 100%;
        position: relative;
        z-index: 0;
        top: 0;
        right: 0;
        border-radius: 0 18px 18px 0;
        margin-left: 30vw;
        transform: translateZ(0);
      }
    }
  }

  .conversations-hscroll {
    background-color: var(--opposite-body);
    padding-left: 7.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 500px;

    .overlay {
      border-radius: 8px;
    }

    .card {
      overflow: hidden;
    }

    h4 {
      color: var(--background);
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .hero {
      overflow: hidden;

      .video-controls {
        left: 0;
        right: 0;
        bottom: 10px;
        display: flex;
        justify-content: center;
        padding: 0 20px;

        &.flushed {
          display: none;
        }
      }

      .overlay.overlay-left {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.5) 20%,
          rgba(0, 0, 0, 0) 100%
        );
      }

      .overlay.hide {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.2) 80%,
          rgba(0, 0, 0, 0) 100%
        );
      }

      .heroBgInfo {
        max-width: unset;
        width: 100vw;
        text-align: center;
        left: 0;
        right: 0;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        bottom: 50px;

        &.flushed {
          right: unset;
          bottom: 0px;

          h1 {
            margin-bottom: 5px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            span {
              display: flex;
              justify-content: flex-end;
              img {
                margin-left: 7.5px;
                height: 40px;
                width: 40px;
              }
            }
          }
        }

        .secondary-filters {
          margin: 10px auto;

          &.hide {
            margin: 0;
            height: 0;
          }
        }

        .actions {
          .button {
            margin: 5px;
          }
        }

        h1 {
          width: 100%;
          span {
            display: none;
          }
        }

        p {
          font-size: 14px;
          margin: 10px 0 20px;
          display: none;

          &.hide {
            height: 0;
            opacity: 0;
          }
        }
      }
    }

    .summary-banner {
      display: none;
    }

    .session-content {
      padding: 10px;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

      .card {
        min-width: 300px;
        width: 300px;
      }
    }

    .interactive-section {
      h2 {
        margin-left: 20px;
        line-height: 1.25;
      }
    }

    .interactive-content {
      &.horizontal {
        padding-bottom: 0;
      }
      a {
        &:first-child {
          margin-left: 20px;
        }
      }
      .card {
        .meta {
          height: 101px;
          width: 101px;
          right: 15px;
          h4 {
            font-size: 30px;
            margin-bottom: 0;
          }
          h5 {
            line-height: 1.1;
          }
        }
        &:hover {
          h3 {
            font-size: 28px;
          }
        }
        h3 {
          font-size: 22px;
          width: 50%;
          max-width: 200px;
          z-index: 1;
          word-wrap: normal;
        }
      }
    }

    .conversations-banner {
      border-radius: 0;
      position: relative;

      .right {
        video {
          margin-left: 0;
        }
      }

      .overlay {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.25) 50%,
          rgba(0, 0, 0, 0) 100%
        );
        width: 100%;
        border-radius: 0;
      }

      .left {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
      }
    }

    .text-banner {
      h1 {
        font-size: 36px;
      }
      padding: 20px;
    }

    .landingBgInfo {
      left: 0;
      width: 100%;
      padding: 20px;
      overflow: hidden;

      h1 {
        font-size: 42px;
      }

      p {
        font-size: 14px;
      }
    }

    video {
      width: 100%;
      left: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .text-banner {
    p {
      margin-bottom: 10px;
    }
  }

  .course-landing {
    .session-content {
      .card {
        width: 100%;

        &.browse-courses {
          text-align: center;
        }
      }
    }
    h3 {
      font-size: 21px;
    }
    .interactive-section {
      .interactive-content {
        flex-direction: row;

        &.horizontal {
          .card {
            width: 300px;
            h3 {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .video-controls {
    img {
      height: 40px;
      width: 40px;
    }
  }

  .heroBgInfo {
    h1 {
      white-space: normal;
    }
  }

  .course-landing {
    .conversations-banner-container {
      padding: 0;

      .conversations-banner {
        img {
          //EF display: none;
        }

        h3 {
          font-size: 31px;
          font-weight: 800;
          margin-bottom: 10px;
        }

        .left {
          justify-content: flex-end;
          margin-bottom: 20px;
          .meta {
            div {
              padding: 10px 5px 0;
              h4 {
                font-size: 41px;
              }
              p {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .interactive-section {
      margin-top: 70px;
    }
  }
}
