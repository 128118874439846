.landing-home {
  .dots {
    display: flex;
    position: absolute;
    bottom: 10vh;
    left: 0;
    right: 0;
    height: 15px;
    z-index: 2;
    justify-content: center;
    .dot {
      height: 8px;
      width: 8px;
      border-radius: 8px;
      background-color: var(--opposite-body-hover);
      margin: 5px;
      cursor: pointer;
      transition: 0.25s ease-in-out;

      &:hover {
        background-color: var(--opposite);
      }

      &.active {
        width: 30px;
        background-color: var(--primary);
      }
    }
  }
  .courses-section {
    h4 {
      font-size: 28px;
    }
  }
  .heading {
    margin-left: 7.5vw;
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 50px;

    h4 {
      font-size: 28px;
      font-weight: 800;
    }
  }
  .has-watched {
    position: relative;
  }
  .session-content {
    padding: 0 0 60px;
    max-width: unset;
    display: flex;
    overflow: auto;
    grid-gap: 20px;
    margin-top: 10px;

    h3 {
      line-height: 1.1;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .card {
      width: 420px;
      min-width: 420px;
      background-position: center;

      &:first-child {
        margin-left: 7.5vw;
      }

      &:last-child {
        margin-right: 20px;
      }

      .content {
        justify-content: flex-start;
        align-items: flex-start;

        p {
          max-height: 124px;
          overflow: hidden;
        }
      }
    }
  }
  .browse-content {
    padding-bottom: 0 !important;
    min-height: 400px !important;
  }

  .hero-content {
    height: 90vh;
    width: 85vw;
    margin: auto;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    position: relative;

    .hero-simple {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      video {
        height: 100%;
      }

      .overlay {
        background: none;
      }
    }

    .body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: auto;
      text-align: center;
      position: relative;

      h2 {
        font-size: 48px;
        font-weight: 800;
        margin-bottom: 10px;
      }

      p {
        max-width: 600px;
      }

      .button {
        max-width: 200px;
        margin-top: 30px;
      }
    }
  }

  .hero-slider {
    position: relative;
    z-index: 0;

    .hero {
      position: relative;
      height: 90vh;
      overflow: hidden;
      .landingBg {
        video {
          z-index: -1;
        }

        .heroBgInfo {
          bottom: 10vh;

          .button-group {
            width: 100%;
            display: flex;

            .button {
              width: 48%;

              img {
                height: 22px;
              }
            }
          }
        }
        .overlay {
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 1) 15%,
            rgba(0, 0, 0, 0.95) 20%,
            rgba(0, 0, 0, 0.45) 30%,
            rgba(0, 0, 0, 0.15) 35%,
            rgba(0, 0, 0, 0) 100%
          );
          z-index: 0;
        }
        .overlay-left {
          bottom: -20px;
          background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 1) 10%,
            rgba(0, 0, 0, 0.99) 30%,
            rgba(0, 0, 0, 0.25) 50%,
            rgba(0, 0, 0, 0) 100%
          );
          z-index: 0;
        }
        .overlay-intense {
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 1) 20%,
            rgba(0, 0, 0, 0.95) 25%,
            rgba(0, 0, 0, 0.65) 35%,
            rgba(0, 0, 0, 0.15) 40%,
            rgba(0, 0, 0, 0) 100%
          );
          z-index: 0;
        }
      }
    }

    video {
      left: unset;
      right: 0;
      width: 70vw;
      height: 90vh;
      object-position: center;
    }

    .video-controls {
      bottom: 15vh;
    }

    .hero-slider-actions {
      img {
        height: 40px;
        opacity: 0.5;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
      .left {
        transform: rotate(90deg);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 10px;
        width: 50px;
        margin: 34.5vh auto;
        z-index: 1;
      }
      .right {
        transform: rotate(-90deg);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        width: 50px;
        margin: 34.5vh auto;
        z-index: 1;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .hero .heroBgInfo .actions .button {
      margin: auto;
      width: 280px;
    }

    .hero {
      .heroBgInfo {
        height: 100px;
        margin-bottom: 0;

        h1 {
          margin-bottom: -5px;
        }
      }

      .secondary-filters {
        &.hide {
          display: flex;
        }
        div {
          margin: 5px;
        }
      }
    }

    .has-watched {
      margin-top: 20px;
    }

    .hero-slider {
      video {
        width: 100vw;
        object-position: center;
        height: 90vh;
      }
    }

    .dots {
      top: 10vh;
      bottom: unset;
    }
    .session-content {
      .card {
        width: 300px;
        min-width: 300px;
      }
    }
    .content {
      width: 100%;
    }
  }
}
