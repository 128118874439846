.bottom-footer {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: var(--background);
  position: relative;

  .company {
    display: flex;
    align-items: center;
    font-size: 12px;
    opacity: 0.5;
  }

  .logo {
    height: 30px;
    width: 30px;
    object-fit: contain;
    position: relative;
    margin-left: 15px;
    margin-right: 10px;
    margin-bottom: 2.5px;
  }

  .social {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .button {
      width: 50px;
      padding: 0;

      img {
        filter: invert(1);
        &:hover {
          opacity: 0.75;
        }
      }
    }
  }
}

.app-footer {
  background-color: var(--backgroundSecondary);
  position: relative;
  padding: 0 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &.partner-footer {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    .container {
      max-width: 800px;
      .column {
        text-align: center;
      }
    }
    .app-store {
      display: flex;
      max-width: 800px;
      margin: 60px auto 0px;

      img {
        height: 50px;
        width: auto;
        margin: 10px;
      }
    }

    .column {
      h6 {
        margin-bottom: 0;
      }
    }
  }

  .container {
    max-width: 1400px;
    margin: auto;
    display: flex;
    justify-content: space-around;
    padding: 40px 0;
  }

  .column {
    width: calc(100vw / 5);
    padding: 10px;

    h6 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 20px;

      span {
        color: var(--primary);
      }
    }

    .items {
      .item {
        margin: 10px 0;
        cursor: pointer;

        span {
          opacity: 0.5;
          &:hover {
            opacity: 1;
          }
        }

        @media screen and (max-width: 768px) {
          img {
            margin-top: 0;
          }
        }
      }
    }

    img {
      height: 46px;
      width: auto;
      margin: 15px 0;
    }
  }
}
