nav {
  padding: 0 40px;
  width: 100%;
  height: 64px;
  background: var(--background);
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 99991;

  .back {
    height: 25px;
    transform: rotate(90deg);
    margin-left: -10px;
    margin-right: -2.5px;
  }

  &.partner-nav {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;

    .app-store {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px auto;
    }
  }

  .navMenuWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.mobileWrapper {
      display: none;
    }
    .navbarLeftMenu {
      display: flex;
      align-items: center;
      .logo {
        text-decoration: none;

        &.dflex {
          display: flex;
          padding: 0;
        }
        img {
          width: 36px;
        }
      }
      .navLink {
        display: flex;
        align-items: center;
        padding: 0;
        text-decoration: none;
        margin: 0 0 0 60px;
        color: var(--default);
        font-weight: 600;
        cursor: pointer;

        img {
          height: 12px;
          margin-top: 2.5px;
          margin-left: 2.5px;
          transition: 0.25s ease-in-out;

          &.inverted {
            transform: rotate(180deg);
          }
        }
      }
    }
    .navbarRightMenu {
      display: flex;
      align-items: center;
      font-weight: 600;
      a {
        padding: 5px 0;
      }
      .button {
        &.link {
          padding: 0 !important;
          width: fit-content;
          opacity: 0.5;
          font-weight: 500;
          padding: 5px 0;

          &:hover {
            opacity: 1;
          }
        }
      }
      .memberStatusWrapper {
        margin-right: 30px;
        button {
          width: 120px;
          height: 30px;
          font-size: 12px;
          background: var(--primary);
          color: var(--background);
          border-radius: 50px;
        }
      }
      div {
        cursor: pointer;
      }
      svg {
        width: 22px;
        color: var(--default);
        cursor: pointer;
      }
      .userIconWrapper {
        width: 34px;
        height: 34px;
        background: var(--background);
        border-radius: 8px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        svg {
          width: 25px;
        }
        .userMenuDropdown {
          width: 320px;
          position: absolute;
          top: 36px;
          right: 0;
          background: #000;
          border-radius: 8px;
          overflow: hidden;
          cursor: auto;
          z-index: 10;
          .userMenuRow {
            padding: 0 24px;
            display: flex;
            align-items: center;
            &.userMenuHeading {
              height: 120px;
              background: #0d0d0d;
              img {
                width: 72px;
                height: 72px;
                border-radius: 50px;
                display: block;
                object-fit: cover;
              }
              h4 {
                padding-left: 20px;
                color: var(--default);
                font-size: 20px;
                font-weight: 700;
                line-height: 1;
              }
            }
            &.userMenuBody {
              height: 104px;
              background: #191919;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              a {
                text-decoration: none;
                font-size: 16px;
                font-weight: 500;
                color: var(--opposite-body);
                opacity: 0.5;
                &:hover {
                  opacity: 1;
                }
                &:not(:first-of-type) {
                  margin: 12px 0 0 0;
                }
              }
            }
            &.userMenuFooter {
              height: 55px;
              background: #333;
              justify-content: center;
              transition: 0.15s ease-in;

              .button {
                width: auto;
                background: none;
                font-size: 16px;
                font-weight: 500;
                color: var(--primary);
                text-transform: capitalize;
              }

              &:hover {
                background-color: var(--primary);
                color: var(--background);

                .button {
                  color: var(--background);
                }
              }
            }
          }
        }
      }
      .openMenuIcon {
        margin-left: 16px;
        display: none;
      }
      .openMenuIconBurger {
        margin-left: 13px;
        width: 30px;
        filter: invert(1);
      }
    }
  }
  @media (max-width: 499px) {
    .navMenuWrapper {
      .navbarRightMenu {
        .userIconWrapper {
          .userMenuDropdown {
            right: -38px;
            top: 53px;
          }
        }
      }
      &.mobileWrapper {
        width: 100%;
      }
    }
  }
  @media (min-width: 500px) and (max-width: 860px) {
    .navMenuWrapper {
      &.mobileWrapper {
        width: 300px;
      }
    }
  }

  @media (max-width: 860px) {
    padding: 0 20px;
    //position: relative;
    z-index: 60;
    backdrop-filter: none;
    .navMenuWrapper {
      .navbarLeftMenu {
        .navLink {
          display: none;
        }
      }
      .navbarRightMenu {
        .memberStatusWrapper {
          display: none;
        }
        .openMenuIcon {
          display: block;
        }
      }
      &.mobileWrapper {
        background: rgba(0, 0, 0, 1);
        padding: 30px 40px;
        position: fixed;
        top: 0;
        left: -100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        transition: all 500ms ease-in-out;
        &.openedMenu {
          left: 0;
        }
        .closeMenuIcon {
          position: absolute;
          top: 12px;
          right: 12px;
          color: var(--default);
          width: 34px;
          cursor: pointer;
          opacity: 0.5;
          &:hover {
            opacity: 1;
          }
        }
        .navbarLeftMenu {
          flex-direction: column;
          align-items: flex-start;
          .logo {
            img {
              display: none;
            }
          }
          .navLink {
            display: block;
            margin: 2px 0 0 6px;
            font-size: 22px;
            color: var(--default);
          }
        }
        .navbarRightMenu {
          margin: 12px 0 0 0;
          .memberStatusWrapper {
            margin-right: 0;
            display: block;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  nav {
    .scroll {
      //background-color: red;
      height: 60px;
      width: 60vw;
      //z-index: 2;
      position: absolute;
      left: 80px;
      right: 80px;
    }
    .scrollable {
      display: flex;
      width: 100vw;
      height: 140px;
      overflow-x: auto;
      overflow-y: hidden;
      margin-bottom: -15px;

      &::-webkit-scrollbar {
        display: none;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        margin-right: 10px;
        height: 120px;

        .item-image {
          height: 100px;
          min-height: 100px;
          width: 120px;
          border-radius: 8px;
          background-size: cover;
          margin-bottom: 5px;
        }

        &:first-child {
          margin-left: 20px;
        }
      }
    }

    .navMenuWrapper.mobileWrapper {
      padding: 0 !important;
      a {
        padding: 2.5px 15px !important;
      }

      /* .secondary {
        position: absolute;
        bottom: 25px;
        left: 15p;
        right: 15px;
        width: calc(100% - 30px);
        margin: auto;
      } */

      .secondary {
        width: calc(100% - 30px);
        margin: 30px auto 20px;
      }
    }
    .navMenuWrapper.mobileWrapper.openedMenu {
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      svg {
        z-index: 2;
      }
    }

    .navMenuWrapper.mobileWrapper .navbarLeftMenu {
      transform: translateX(-20px);
      padding-top: 2px;

      a {
        &.small {
          margin-top: 18px;
          margin-bottom: -10px;

          svg {
            height: 20px;
            margin-left: 5px;
          }
        }

        &.danger {
          color: var(--danger);
        }
      }
    }

    .navMenuWrapper.mobileWrapper.openedMenu .navbarLeftMenu {
      transform: translateX(0);
    }

    .navMenuWrapper.mobileWrapper .navbarLeftMenu .navLink {
      &.small {
        font-size: 16px;
        opacity: 0.75;
        font-weight: 400;
        margin-bottom: -15px;
      }
    }
  }
}
