.dropdown {
  position: relative;
  .dropdown-selected {
    padding: 0 16px;
    width: 100%;
    height: 64px;
    border: none;
    outline: none;
    color: var(--background);
    border-radius: 8px;
    background-color: var(--default);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 20px;

    img {
      filter: invert(1);
    }
  }

  .dropdown-options {
    position: absolute;
    left: 0;
    right: 0;
    top: 80px;
    background-color: var(--default);
    border-radius: 8px;
    margin-top: -7.5px;
    z-index: 1;
    max-height: 400px;
    overflow: auto;

    .dropdown-option {
      padding: 15px;
      color: var(--background);
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        background-color: var(--opposite);
      }
    }
  }
}
