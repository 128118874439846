.home {
  width: 100%;
  min-height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 100vh;

  .full-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center 50px !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }

  video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    background-color: black !important;
  }
  .homeMain {
    position: relative;
    margin: 0;
    height: 100vh;
    width: 100vw;
    min-height: calc(100vh - 188px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.45);

    .homeContainer {
      margin-top: 50px;
      .homeInfo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .homeDesc {
          max-width: 500px;
          padding: 30px;
          background: black;
          border-radius: 16px;
          .collab-container {
            display: flex;
            width: 100%;
            justify-content: center;

            .collab {
              font-size: 3em;
              color: white;
              z-index: 1;
              margin: 0 20px;
            }

            img {
              width: 72px;
              height: 72px;
              height: auto;
              display: block;
              object-fit: contain;
              position: relative;
            }
          }
          h2 {
            color: #fff;
            font-size: 36px;
            font-weight: 800;
            line-height: 1.3;
            text-align: center;
            margin: 40px 0 0px;
          }
          p {
            margin: 20px 0 30px 0;
            color: rgba(255, 255, 255, 0.85);
            font-size: 20px;
            font-weight: 400;
            text-align: center;
          }
          .homeBtnWrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            button {
              width: 50%;
              height: 60px;
              font-size: 16px;
              display: flex;
              align-items: center;
              overflow: hidden;
              margin-right: 15px;
              img {
                width: 190px;
                height: auto;
                display: block;
                object-fit: cover;
              }
            }
          }
          .homeBottomInfo {
            padding: 50px 0 0 0;
            text-align: center;
            p {
              margin: 0;
              color: rgba(255, 255, 255, 0.85);
              font-size: 18px;
              font-weight: 600;
            }
            a {
              margin: 4px 0 0 0;
              text-decoration: none;
              display: inline-block;
              color: #0088ff;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
      img {
        width: 100%;
        height: auto;
        display: block;

        object-fit: contain;
        max-height: 80vh;
      }
    }
  }
  .homeFooter {
    padding: 4px 0 0 0;
    width: 100%;
    max-width: 93%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .homeFooterInfo {
      display: flex;
      align-items: center;
      img {
        width: 44px;
        height: auto;
        display: block;
        object-fit: cover;
      }
      p {
        padding: 1px 0 0 14px;
        margin: 0;
        line-height: 1;
        color: #2c2e33;
        font-size: 15px;
        font-weight: 400;
      }
    }
    .homeFooterSocials {
      display: flex;
      align-items: center;
    }
  }
  .show-mobile {
    display: none;
  }

  @media (max-width: 776px) {
    .home {
      height: calc(100vh - 64px);
    }
    .show-mobile {
      display: block;
      align-self: center;
      width: 320px;
      margin-left: -5px;
      margin-bottom: 20px;
    }
    .homeMain {
      height: calc(100vh - 64px);
      align-items: flex-end;
      flex-direction: column;
      justify-content: flex-end;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.3799894957983193) 75%,
        rgba(0, 0, 0, 0) 100%
      );

      .homeContainer {
        margin-top: 0;

        .homeInfo {
          .homeDesc {
            h2 {
              font-size: 30px;
              margin: 20px 0 0px;
            }
            p {
              font-size: 18px;
              br {
                display: none;
              }
            }
            .homeBtnWrapper button img {
              width: 150px;
            }
            .homeBottomInfo {
              padding: 30px 0 0 0;
              p {
                font-size: 16px;
              }
              a {
                font-size: 14px;
              }
            }
          }
        }

        img {
          display: none;
        }
      }
    }
    .homeFooter {
      height: 54px;
      .homeFooterInfo {
        img {
          width: 34px;
        }
        p {
          padding: 0px 0 0 8px;
          font-size: 14px;
        }
      }
    }
  }
  @media (min-width: 777px) and (max-width: 1140px) {
    .homeMain {
      .homeContainer {
        .homeInfo {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .homeDesc {
            h2 {
              font-size: 37px;
            }
            p {
              margin: 20px 0 26px 0;
              font-size: 18px;
              br {
                display: none;
              }
            }
          }
        }
        img {
          width: 100%;
          height: auto;
          display: block;
          object-fit: cover;
        }
      }
    }
  }
}
