.pricing-page-new {
  h4 {
    font-size: 28px;
  }

  .hero {
    overflow: hidden;
    height: 45vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    text-align: center;

    .landingBgInfo {
      position: relative;
    }

    hr {
      width: 59px;
      border-width: 3px;
      border-color: var(--primary);
      margin: 30px auto;
    }

    span {
      color: var(--primary);
      font-weight: 800;
    }
  }

  h1 {
    font-size: 72px;
    font-weight: 800;
    line-height: 1;
  }

  .faq-landing h1 {
    font-size: 41px;
  }

  p,
  .paragraph {
    font-weight: 500;
    font-size: 20px;
  }

  .sub-body {
    margin-top: 15px;
  }

  video {
    object-fit: cover;
    object-position: center -5vh;
    width: 100vw;
    height: calc(45vh);
    position: absolute;
    top: 60px;
    left: 0;
    z-index: -1;
  }

  .badge {
    height: 28px;
    color: var(--background);
    background-color: var(--primary);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    padding: 0 10px;
    margin-left: 7.5px;
  }

  .content {
    background-color: var(--opposite);

    .card-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 60px 0 20px;

      .card {
        height: 790px;
        width: 460px;
        margin: 20px;
        border-radius: 16px;
        background-color: var(--background);
        padding: 35px;

        a {
          color: var(--callout);
        }

        p {
          margin: 10px 0;
        }

        .button {
          margin: 30px auto 40px;
        }

        ul {
          margin: 20px 0;
          li {
            display: flex;
            align-items: flex-start;
            margin: 20px 0;

            img {
              margin-top: 5px;
              margin-right: 10px;
            }
          }
        }

        .price-header {
          display: flex;
          align-items: center;
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 20px;

          img {
            height: 72px;
            width: 118px;
            object-fit: cover;
            margin-right: 15px;
          }
        }
        .price {
          display: flex;
          align-items: center;
          justify-content: center;

          h4 {
            text-decoration: line-through;
            text-decoration-color: var(--primary);
            font-size: 36px;
            font-weight: 500;
            margin-right: 10px;
            margin-left: -10px;
          }
          h3 {
            font-size: 64px;
            font-weight: 800;
            position: relative;

            span {
              position: absolute;
              right: -30px;
              top: 0;
            }
          }
        }
      }
    }
  }

  .pricing-banner {
    background-color: var(--background);
    display: flex;
    max-width: 970px;
    border-radius: 16px;
    margin: 0 auto 60px;

    h3 {
      font-size: 32px;
      font-weight: 700;
    }

    p {
      font-size: 14px;
      margin-bottom: 20px;
    }

    .button {
      margin-bottom: 10px;
      width: fit-content;
    }

    div {
      padding: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 768px) {
    .pricing-banner {
      flex-direction: column;
      text-align: center;
      padding: 20px 0;
      img {
        display: none;
      }

      div {
        align-items: center;
      }

      h3 {
        line-height: 1.1;
        margin-bottom: 20px;
        font-size: 21px;
      }
    }

    .faq-landing {
      .faq {
        padding: 0 0 20px !important;
      }

      h3 {
        font-size: 18px;
        font-weight: 700;
      }
      h1 {
        font-size: 24px;
      }
      .section {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        h5 {
          font-size: 16px;
          max-width: 99%;
        }
      }
    }

    .faq {
      padding: 0 0 40px;
    }

    .content {
      padding: 15px;
      p {
        font-size: 14px;
      }
      .card-container {
        flex-direction: column;
        padding: 20px 0 20px;

        .card {
          width: 100%;
          height: fit-content;
          padding: 20px;
          font-size: 14px;
          .price-header {
            font-size: 28px;
            justify-content: center;
            font-weight: 800;
            line-height: 1.25;
            margin-bottom: 10px;

            img {
              display: none;
            }
          }

          .paragraph {
            font-size: 14px;
            text-align: center;
          }

          .price {
            h3 {
              font-size: 42px;
            }

            h4 {
              font-size: 32px;
            }
          }
        }
      }
    }
  }
}
