.interactive-content {
  max-width: 1311px;
  grid-gap: 20px;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(2, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  overflow-x: auto;
  position: relative;
  z-index: 2;
  margin: auto;
  padding: 40px 0;

  .card {
    overflow: hidden;
    position: relative;
    padding: 2.5vw;
    border-radius: 12px;
    height: 296px;
    background: rgb(36, 0, 60);
    background: linear-gradient(
      101.56deg,
      #28252c 0.69%,
      #2e232d 49.29%,
      #2a202b 98.83%
    );
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    img {
      position: absolute;
      right: 15px;
      bottom: 0;
      height: 100%;
      width: auto;
      opacity: 0;
      transition: 0.25s ease-in-out;
    }

    .meta {
      position: absolute;
      right: 25px;

      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      background: linear-gradient(
        153.75deg,
        rgba(255, 255, 255, 0.16) 12.72%,
        rgba(255, 255, 255, 0) 96.39%
      );
      border-radius: 50%;
      height: 180px;
      width: 180px;
      color: var(--default);
      padding-bottom: 10px;
      margin-left: 15px;
      transition: 0.25s ease-in-out;

      h4 {
        font-size: 80px;
        font-weight: 800;
        margin-bottom: -10px;
      }

      h5 {
        font-size: 12px;
        opacity: 0.5;
        text-align: center;
      }
    }

    h3 {
      font-size: 31px;
      font-weight: 800;
      position: relative;
      text-transform: capitalize;
      line-height: 1.1;
    }

    .title {
      width: 50%;
      white-space: normal;
    }
  }

  &.horizontal {
    position: relative;
    display: flex;
    z-index: 2;
    overflow-x: auto;
    white-space: nowrap;
    height: 100%;
    &::-webkit-scrollbar {
      display: none;
    }

    .card {
      flex: 0 0 auto;
      width: 500px;
    }
  }
}

.interactive-landing {
  position: relative;
  color: var(--default);
  min-height: 100vh;

  .courses-section {
    padding: 0px 0 80px;

    @media screen and (max-width: 768px) {
      padding: 20px 0;
    }
  }

  .secondary-filters {
    display: flex;
    margin-top: 30px;

    img {
      margin-right: 7.5px;
    }
    div {
      margin-right: 15px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
      border: 1px solid var(--secondary-hover);
      //background-color: var(--background);
      border-radius: 22px;
      font-size: 12px;
      transition: 0.25s ease-in-out;
    }
  }

  h1 {
    font-size: 72px;
    font-weight: 800;
    line-height: 1;
  }

  p {
    font-weight: 500;
    font-size: 20px;
  }

  video {
    object-fit: cover;
    object-position: top center;
    width: 100vw;
    height: calc(85vh - 60px);
    position: absolute;
    top: 60px;
    left: 0;
    z-index: -1;
  }

  .video-controls {
    position: relative;
    position: absolute;
    bottom: 20px;
    right: 35px;
    display: flex;

    img {
      cursor: pointer;
      margin: 5px;
    }
  }

  .landingBgInfo {
    position: relative;
    color: var(--default);
    max-width: 680px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 7.5vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .call-to-action {
      display: flex;
      max-width: 400px;

      input,
      .button {
        height: 52px !important;
      }
      .button {
        width: 134px;
        margin-left: 15px;
      }
    }

    p {
      margin: 20px 0 30px;
      font-weight: 400;
    }
    b {
      text-decoration: underline;
    }
  }

  .opposite-body {
    background-color: var(--opposite-body);
    padding: 60px 30px;
  }

  .summary-banner {
    height: 117px;
    max-width: 1311px;
    margin: 40px auto 10px;
    background: linear-gradient(125.25deg, #535353 3.46%, #000000 100%);
    border-radius: 8px;
    color: var(--default);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 30px;

    .avatar {
      height: 78px;
      width: 78px;
      border-radius: 50%;
      object-fit: cover;
    }

    section {
      width: calc(100% - 240px);
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        padding: 0 40px;
      }
    }

    div {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 800;
      border-right: 1px solid var(--default);
      justify-content: center;

      img {
        margin-right: 5px;
      }

      &.last-child {
        border: none;
      }
    }

    .button {
      height: 52px;
      width: 176px;
      img {
        filter: invert(1);
      }
    }
  }

  .text-banner {
    color: var(--background);
    text-align: center;
    max-width: 954px;
    margin: 20px auto;

    h1 {
      font-size: 54px;
      font-weight: 800;
      margin-bottom: 20px;
    }
    p {
      font-size: 20px;
    }
  }

  .conversations-banner {
    height: calc(100vh - 60px);
    background-color: var(--background);
    display: flex;
    border-radius: 18px;
    position: relative;
    margin: 64px 0 0;

    h3 {
      font-size: 42px;
      font-weight: 700;
      line-height: 1.25;
    }

    .actions {
      opacity: 1;
      display: flex;
      justify-content: flex-start;
      margin: 25px auto 0;

      .primary,
      .secondary {
        height: 52px;
        width: 232px;
        margin-right: 15px;
        display: flex;
        margin-bottom: 20px;

        img {
          display: block;
          margin-right: 5px;
        }
      }

      .primary {
        img {
          filter: invert(1);
        }
      }
    }

    .overlay {
      background: linear-gradient(
        90deg,
        rgb(43, 34, 47) 13.29%,
        rgba(43, 34, 47, 1) 40%,
        rgba(43, 34, 47, 0.2) 55%,
        rgba(43, 34, 47, 0) 100%
      );
      z-index: 1;
    }

    .left {
      text-align: center;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      margin: auto;

      .badge {
        display: flex;
        border: 1px solid var(--default);
        border-radius: 50px;
        padding: 10px 15px;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        font-size: 14px;
      }

      img {
        height: 20px;
        margin-right: 10px;
      }

      .meta {
        display: flex;

        div {
          padding: 20px;
        }

        h4 {
          font-size: 41px;
          margin-bottom: 0;
          font-weight: 700;
        }
        h5 {
          line-height: 1.1;
        }
      }
    }

    .right {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      overflow: hidden;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center bottom;
      height: 100%;

      video {
        height: 100%;
        position: relative;
        z-index: 0;
        top: 0;
        right: 0;
        margin-left: 40vw;
      }
    }
  }

  .conversations-hscroll {
    background-color: var(--opposite-body);
    padding-left: 7.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 500px;

    .overlay {
      border-radius: 8px;
    }

    .card {
      overflow: hidden;
    }

    h4 {
      color: var(--background);
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .interactive-content {
    display: flex;
    flex-direction: column;

    .card {
      width: 100%;
      height: 196px;
      padding: 15px;

      .meta {
        height: 108px;
        width: 108px;
        top: 0;
        right: 15px;
        bottom: 0;

        margin: auto;
        h4 {
          font-size: 40px;
        }
      }
    }
  }

  .interactive-landing {
    .opposite-body {
      padding: 30px 15px;
    }

    .text-banner {
      h1 {
        font-size: 31px;
      }
    }

    .conversations-banner {
      position: relative;
      .overlay {
        background: linear-gradient(
          0deg,
          rgba(43, 34, 47, 1) 0%,
          rgba(43, 34, 47, 0.8) 20%,
          rgba(43, 34, 47, 0.1) 40%,
          rgba(43, 34, 47, 0) 100%
        );
        z-index: 1;
      }

      .left {
        width: 100%;
        justify-content: flex-end;

        .badge {
          margin-bottom: 15px;
        }

        h3 {
          font-size: 21px;
          font-weight: 800;
          line-height: 1.1;
        }

        .meta {
          div {
            padding: 5px 20px;
            h4 {
              font-size: 41px;
              margin-bottom: -5px;
            }
            p {
              font-size: 12px;
            }
          }
        }

        .actions {
          display: flex;
          justify-content: space-between;
          .button {
            width: 42.5vw;
            white-space: nowrap;
            margin-right: 0;
            margin: 0 5px 0 5px;
          }
        }
      }
      .right {
        video {
          margin-left: 0;
        }
      }
    }
  }
}
