.sport-page {
  min-height: 800px;
  .header {
    height: 45vh;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    padding: 10vh 20px 5vh;
    position: relative;

    .overlay {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    h1 {
      font-size: 70px;
      font-weight: 800;
      position: relative;
      text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.25);
    }

    p {
      position: relative;
      font-size: 14px;
    }
  }

  .explore-banner {
    background-position: center;
    background-size: cover;
    max-width: 1277px;
    height: 428px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto 120px;
    position: relative;

    .overlay {
      background: rgba(0, 0, 0, 0.65);
    }

    h3 {
      font-size: 28px;
      font-weight: 800;
    }

    img {
      position: relative;
    }

    p,
    h3 {
      margin: 10px auto;
      position: relative;
    }

    .button {
      color: var(--background);
      width: 232px;
      position: relative;
    }
  }

  @media screen and (max-width: 768px) {
    .header {
      height: 40vh;
      background-position: center bottom;
      margin-top: 64px;

      .overlay {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.7) 50%,
          rgba(0, 0, 0, 0) 100%
        );
      }

      h1 {
        font-size: 40px;
        text-align: center;
        line-height: 1.1;
      }

      p {
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
