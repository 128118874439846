.download-app {
  text-align: center;
  flex-direction: column;
  text-align: center;

  .close {
    position: absolute;
    top: 25px;
    right: 25px;
    height: 25px;
    opacity: 0.75;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .content {
    padding: 20px;
    .logo {
      height: 72px;
      margin: 0 auto 20px;
    }
    max-width: 720px;
    margin: auto;

    h1 {
      font-size: 46px;
      font-weight: 800;
      line-height: 54px;
      margin-bottom: 30px;
    }

    p {
      opacity: 0.85;
      font-size: 20px;
    }

    .button-group {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        margin: 5px;
      }
    }
    .footer-text {
      padding: 50px 0 0 0;
      text-align: center;
      p {
        margin: 0;
        color: rgba(255, 255, 255, 0.85);
        font-size: 18px;
        font-weight: 600;
      }
      .button {
        max-width: 280px;
        margin: auto;
      }

      a {
        margin: 4px 0 0 0;
        text-decoration: none;
        display: inline-block;
        color: var(--callout);
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
