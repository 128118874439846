.segment {
  .block.talk-block .resting-image {
    height: 245px;
  }

  .sidebar .sidebar-header .tabs .tab {
    padding: 0 0 5px;
  }

  @media screen and (max-width: 768px) {
    .browse-content {
      .courses-section {
      }
    }
  }
}
