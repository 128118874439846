.roster {
  .header {
    height: 65vh;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    padding: 10vh 20px 5vh;
    position: relative;

    h1 {
      font-size: 112px;
      font-weight: 800;
      position: relative;
    }
  }
  .roster-content {
    max-width: 1400px;
    margin: 20px auto 60px;
    padding: 0 20px;
    h2 {
      font-size: 50px;
      font-weight: 700;
    }

    .filters {
      display: flex;
      align-items: center;
      margin: 30px 0 20px;
      &::-webkit-scrollbar {
        display: none;
      }

      h4 {
        font-size: 18px;
        font-weight: 800;
      }
    }
    .secondary-filters {
      display: flex;
      margin-bottom: 30px;

      img {
        margin-right: 7.5px;
      }
      div {
        margin-right: 15px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
        border: 1px solid var(--secondary-hover);
        border-radius: 22px;
        font-size: 12px;
        transition: 0.25s ease-in-out;
        cursor: pointer;

        &.active {
          background-color: var(--primary);
          color: var(--background);
          border-color: var(--primary);

          img {
            filter: invert(1);
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .header {
      height: 40vh;
      background-position: center 22px;

      h1 {
        font-size: 40px;
        text-align: center;
        line-height: 1.1;
      }
    }
    .roster-content {
      margin: 0;
      padding: 0 15px;

      h2 {
        display: none;
      }

      .filters {
        overflow: auto;
      }
    }
  }
}
