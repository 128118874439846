.video-modal {
  .close {
    position: absolute;
    top: 25px;
    right: 25px;
    height: 25px;
    opacity: 0.75;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    white-space: nowrap;

    .button {
      width: 150px;
      margin: 10px 5px;

      img {
        margin-right: 5px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
  h3 {
    font-size: 20px;
    font-weight: 800;
  }
  video {
    max-width: 1000px;
  }

  @media screen and (max-width: 768px) {
    .content .header {
      flex-direction: column;
      text-align: center;
    }
  }
}
